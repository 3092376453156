// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Box = require("../Box/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var CostEstimation_AddComponent = require("../CostEstimation.AddComponent/index.js");
var CostEstimation_EstimationFunctions = require("../CostEstimation.EstimationFunctions/index.js");
var CostEstimation_Styles = require("../CostEstimation.Styles/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Array_NonEmpty = require("../Data.Array.NonEmpty/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_Map_Internal = require("../Data.Map.Internal/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Ordering = require("../Data.Ordering/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Edited = require("../Edited/index.js");
var Effect = require("../Effect/index.js");
var EstimationUtils = require("../EstimationUtils/index.js");
var Formatters = require("../Formatters/index.js");
var Gap = require("../Gap/index.js");
var LengthValidator = require("../LengthValidator/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var SocialExpenseIndicator = require("../SocialExpenseIndicator/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoCheckbox = require("../TacoCheckbox/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoIcon = require("../TacoIcon/index.js");
var TacoIcon_Icons_Types = require("../TacoIcon.Icons.Types/index.js");
var TacoInput = require("../TacoInput/index.js");
var TacoInput_Styles = require("../TacoInput.Styles/index.js");
var TacoTable = require("../TacoTable/index.js");
var TacoTable_Cells = require("../TacoTable.Cells/index.js");
var TacoTable_Types = require("../TacoTable.Types/index.js");
var TacoText = require("../TacoText/index.js");
var TacoText_Types = require("../TacoText.Types/index.js");
var Types_BuildingElementsView = require("../Types.BuildingElementsView/index.js");
var Types_ClassificationGroup = require("../Types.ClassificationGroup/index.js");
var Types_Component = require("../Types.Component/index.js");
var Types_CostEstimation = require("../Types.CostEstimation/index.js");
var Types_Element = require("../Types.Element/index.js");
var Types_Estimation = require("../Types.Estimation/index.js");
var Types_Newtypes = require("../Types.Newtypes/index.js");
var Types_Project = require("../Types.Project/index.js");
var Utils = require("../Utils/index.js");
var ColSubprojectCode = (function () {
    function ColSubprojectCode() {

    };
    ColSubprojectCode.value = new ColSubprojectCode();
    return ColSubprojectCode;
})();
var ColCode = (function () {
    function ColCode() {

    };
    ColCode.value = new ColCode();
    return ColCode;
})();
var ColDescription = (function () {
    function ColDescription() {

    };
    ColDescription.value = new ColDescription();
    return ColDescription;
})();
var ColRTCode = (function () {
    function ColRTCode() {

    };
    ColRTCode.value = new ColRTCode();
    return ColRTCode;
})();
var ColRtDescription = (function () {
    function ColRtDescription() {

    };
    ColRtDescription.value = new ColRtDescription();
    return ColRtDescription;
})();
var ColAmount = (function () {
    function ColAmount() {

    };
    ColAmount.value = new ColAmount();
    return ColAmount;
})();
var ColUnit = (function () {
    function ColUnit() {

    };
    ColUnit.value = new ColUnit();
    return ColUnit;
})();
var ColPrice = (function () {
    function ColPrice() {

    };
    ColPrice.value = new ColPrice();
    return ColPrice;
})();
var ColPriceUnits = (function () {
    function ColPriceUnits() {

    };
    ColPriceUnits.value = new ColPriceUnits();
    return ColPriceUnits;
})();
var ColTotal = (function () {
    function ColTotal() {

    };
    ColTotal.value = new ColTotal();
    return ColTotal;
})();
var ColFlags = (function () {
    function ColFlags() {

    };
    ColFlags.value = new ColFlags();
    return ColFlags;
})();
var ColGroup = (function () {
    function ColGroup() {

    };
    ColGroup.value = new ColGroup();
    return ColGroup;
})();
var ColCheckbox = (function () {
    function ColCheckbox() {

    };
    ColCheckbox.value = new ColCheckbox();
    return ColCheckbox;
})();
var ColTotalUnit = (function () {
    function ColTotalUnit() {

    };
    ColTotalUnit.value = new ColTotalUnit();
    return ColTotalUnit;
})();
var ColProject = (function () {
    function ColProject() {

    };
    ColProject.value = new ColProject();
    return ColProject;
})();
var BySubprojectCode = (function () {
    function BySubprojectCode() {

    };
    BySubprojectCode.value = new BySubprojectCode();
    return BySubprojectCode;
})();
var ByCode = (function () {
    function ByCode() {

    };
    ByCode.value = new ByCode();
    return ByCode;
})();
var ByDescription = (function () {
    function ByDescription() {

    };
    ByDescription.value = new ByDescription();
    return ByDescription;
})();
var ByRTCode = (function () {
    function ByRTCode() {

    };
    ByRTCode.value = new ByRTCode();
    return ByRTCode;
})();
var ByRtDescription = (function () {
    function ByRtDescription() {

    };
    ByRtDescription.value = new ByRtDescription();
    return ByRtDescription;
})();
var ByAmount = (function () {
    function ByAmount() {

    };
    ByAmount.value = new ByAmount();
    return ByAmount;
})();
var ByUnit = (function () {
    function ByUnit() {

    };
    ByUnit.value = new ByUnit();
    return ByUnit;
})();
var ByPrice = (function () {
    function ByPrice() {

    };
    ByPrice.value = new ByPrice();
    return ByPrice;
})();
var ByTotal = (function () {
    function ByTotal() {

    };
    ByTotal.value = new ByTotal();
    return ByTotal;
})();
var getSortingValue = function (sortKey) {
    return function (v) {
        if (sortKey instanceof BySubprojectCode) {
            return new TacoTable_Types.StringValue(v.subprojectCode);
        };
        if (sortKey instanceof ByCode) {
            return TacoTable_Types.StringValue.create(Data_Newtype.unwrap()(v.element.code));
        };
        if (sortKey instanceof ByDescription) {
            return new TacoTable_Types.StringValue(v.element.description);
        };
        if (sortKey instanceof ByRTCode) {
            return new TacoTable_Types.StringValue(v.element.rtCode);
        };
        if (sortKey instanceof ByRtDescription) {
            return new TacoTable_Types.StringValue(v.element.rtDescription);
        };
        if (sortKey instanceof ByAmount) {
            return new TacoTable_Types.NumberValue(v.amount);
        };
        if (sortKey instanceof ByUnit) {
            return new TacoTable_Types.StringValue(v.element.unit);
        };
        if (sortKey instanceof ByPrice) {
            return new TacoTable_Types.NumberValue(v.unitPrice);
        };
        if (sortKey instanceof ByTotal) {
            return new TacoTable_Types.NumberValue(v.totalPrice);
        };
        throw new Error("Failed pattern match at CostEstimation.BuildingElementsView.Table (line 500, column 3 - line 509, column 42): " + [ sortKey.constructor.name ]);
    };
};
var getRow = function (columns) {
    return function (props) {
        return function (idx) {
            return function (v) {
                var selected = Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqInt))(props.elementProps.selectedElementIdx)(new Data_Maybe.Just(idx));
                var onClick = function (v1) {
                    return Data_Monoid.guard(Data_Monoid.monoidFn(Data_Monoid.monoidFn(Effect.monoidEffect(Data_Monoid.monoidUnit))))(!v1.elementsData.isTopicRow)(props.elementProps.setDetailsElementIdx)(false)(new Edited.Edited(new Data_Maybe.Just(idx)));
                };
                var isActiveMgLink = (function () {
                    if (v.active_measurement_groups instanceof Data_Maybe.Just && v.dixonElementLinks instanceof Data_Maybe.Just) {
                        var matching_mg_ids = Data_Array.intersect(Data_Eq.eqInt)(Data_Functor.map(Data_Functor.functorArray)(function (v1) {
                            return v1.measurement_group_id;
                        })(v.dixonElementLinks.value0))(Data_Functor.map(Data_Functor.functorArray)(function (v1) {
                            return v1.id;
                        })(v.active_measurement_groups.value0));
                        return Data_Array.length(matching_mg_ids) > 0;
                    };
                    return false;
                })();
                var className = TacoTable_Cells.classNames([ Data_Monoid.guard(Data_Monoid.monoidString)(selected)("selected"), Data_Monoid.guard(Data_Monoid.monoidString)(v.elementsData.isTopicRow)("topic-component"), Data_Monoid.guard(Data_Monoid.monoidString)(isActiveMgLink)("dixon-link") ]);
                return {
                    className: className,
                    rowData: v,
                    foldingElement: Data_Maybe.Nothing.value,
                    onClick: onClick,
                    rowKey: Data_Show.show(Types_Element.showElementId)((Data_Newtype.unwrap()(v.elementsData.element)).elementId),
                    columns: columns
                };
            };
        };
    };
};
var getElementsTableSortProperty = {
    getName: function (v) {
        return "ElementsTableSortProperty";
    }
};
var getColumnSizeOffset = function (columnSizeOffsets) {
    return function (key) {
        return Data_Maybe.fromMaybe(0.0)(Data_Map_Internal.lookup(Data_Ord.ordString)(key)(columnSizeOffsets));
    };
};
var prepareColumns = function (viewMode) {
    return function (props) {
        return function (v) {
            return function (fn) {
                var viewModeDependentCols = (function () {
                    if (viewMode instanceof Types_CostEstimation.EditMode) {
                        return [ {
                            col: ColFlags.value,
                            key: "flags",
                            label: React_Basic_DOM.text("Merkinn\xe4t"),
                            width: new TacoTable_Types.Fixed("8rem"),
                            headerJSX: Data_Maybe.Nothing.value
                        }, {
                            col: ColSubprojectCode.value,
                            key: "subprojectCode",
                            label: React_Basic_DOM.text("AK"),
                            width: new TacoTable_Types.FlexResizeable(1, "3rem", 0.0),
                            headerJSX: Data_Maybe.Nothing.value
                        }, {
                            col: ColGroup.value,
                            key: "group",
                            label: React_Basic_DOM.text("Suoriteryhm\xe4t"),
                            width: new TacoTable_Types.FlexResizeable(2, "10rem", 0.0),
                            headerJSX: Data_Maybe.Nothing.value
                        } ];
                    };
                    if (viewMode instanceof Types_CostEstimation.ReferenceMode) {
                        if (props.shortenReferenceProjectDetails) {
                            return [ {
                                col: ColProject.value,
                                key: "project",
                                label: React_Basic_DOM.text("Hanke"),
                                width: new TacoTable_Types.Fixed("5rem"),
                                headerJSX: Data_Maybe.Nothing.value
                            } ];
                        };
                        return [ {
                            col: ColProject.value,
                            key: "project",
                            label: React_Basic_DOM.text("Hanke"),
                            width: new TacoTable_Types.Fixed("24rem"),
                            headerJSX: Data_Maybe.Nothing.value
                        } ];
                    };
                    throw new Error("Failed pattern match at CostEstimation.BuildingElementsView.Table (line 578, column 29 - line 586, column 118): " + [ viewMode.constructor.name ]);
                })();
                var selectAllCheckbox = TacoCheckbox.component()()({
                    className: "select-all-checkbox",
                    isChecked: !v.isSelectionEmpty,
                    onToggle: new TacoCheckbox.NoEvent(v.onToggleSelectAll),
                    testId: "select-all-checkbox",
                    color: TacoConstants.elementActionColors.border
                });
                var mkSocialExpenseLabel = function (text) {
                    return Gap.gapX(TacoConstants.XS.value)([ Data_Monoid.guard(React_Basic.monoidJSX)(props.showSocialExpensesInCosts)(SocialExpenseIndicator.socialExpenseIndicator), React_Basic_DOM.text(text) ]);
                };
                var totalPriceLabel = mkSocialExpenseLabel("Yhteens\xe4");
                var mkCol = function (v1) {
                    var $88 = fn(v1.col);
                    return {
                        sortProperty: $88.sortProperty,
                        key: v1.key,
                        label: v1.label,
                        width: (function () {
                            if (v1.width instanceof TacoTable_Types.FlexResizeable) {
                                if (props.columnSizeOffsets instanceof Data_Maybe.Just) {
                                    return new TacoTable_Types.FlexResizeable(v1.width.value0, v1.width.value1, getColumnSizeOffset(props.columnSizeOffsets.value0)(v1.key));
                                };
                                return new TacoTable_Types.FlexMinWidth(v1.width.value0, v1.width.value1);
                            };
                            return v1.width;
                        })(),
                        cell: $88.cell,
                        headerJSX: v1.headerJSX
                    };
                };
                var currencySymbol = EstimationUtils.getCurrencySymbol(props.projectCurrency);
                var unitForUnitPriceLabel = mkSocialExpenseLabel(currencySymbol + "/Yks.");
                return Data_Functor.map(Data_Functor.functorArray)(mkCol)(Data_Semigroup.append(Data_Semigroup.semigroupArray)([ {
                    col: ColCheckbox.value,
                    key: "checkbox",
                    label: React_Basic_DOM.text(""),
                    width: new TacoTable_Types.Fixed("2rem"),
                    headerJSX: new Data_Maybe.Just(selectAllCheckbox)
                } ])(Data_Semigroup.append(Data_Semigroup.semigroupArray)(viewModeDependentCols)([ {
                    col: ColCode.value,
                    key: "code",
                    label: React_Basic_DOM.text("Luokka"),
                    width: new TacoTable_Types.FlexResizeable(2, "6rem", 0.0),
                    headerJSX: Data_Maybe.Nothing.value
                }, {
                    col: ColDescription.value,
                    key: "description",
                    label: React_Basic_DOM.text("Luokan nimi"),
                    width: new TacoTable_Types.FlexResizeable(2, "10rem", 0.0),
                    headerJSX: Data_Maybe.Nothing.value
                }, {
                    col: ColRTCode.value,
                    key: "rtCode",
                    label: React_Basic_DOM.text("Koodi"),
                    width: new TacoTable_Types.FlexResizeable(1, "7rem", 0.0),
                    headerJSX: Data_Maybe.Nothing.value
                }, {
                    col: ColRtDescription.value,
                    key: "rtDescription",
                    label: React_Basic_DOM.text("Rakenteen selite"),
                    width: new TacoTable_Types.FlexResizeable(10, "16rem", 0.0),
                    headerJSX: Data_Maybe.Nothing.value
                }, {
                    col: ColAmount.value,
                    key: "amount",
                    label: React_Basic_DOM.text("M\xe4\xe4r\xe4"),
                    width: new TacoTable_Types.FlexResizeable(1, "6rem", 0.0),
                    headerJSX: Data_Maybe.Nothing.value
                }, {
                    col: ColUnit.value,
                    key: "unit",
                    label: React_Basic_DOM.text("Yks."),
                    width: new TacoTable_Types.FlexResizeable(1, "4rem", 0.0),
                    headerJSX: Data_Maybe.Nothing.value
                }, {
                    col: ColPrice.value,
                    key: "price",
                    label: React_Basic_DOM.text(""),
                    width: new TacoTable_Types.FlexResizeable(1, "7rem", 0.0),
                    headerJSX: Data_Maybe.Nothing.value
                }, {
                    col: ColPriceUnits.value,
                    key: "eur-per-unit",
                    label: unitForUnitPriceLabel,
                    width: new TacoTable_Types.FlexResizeable(1, "4rem", 0.0),
                    headerJSX: Data_Maybe.Nothing.value
                }, {
                    col: ColTotal.value,
                    key: "total",
                    label: totalPriceLabel,
                    width: new TacoTable_Types.FlexResizeable(1, "7rem", 0.0),
                    headerJSX: Data_Maybe.Nothing.value
                }, {
                    col: ColTotalUnit.value,
                    key: "totalUnit",
                    label: React_Basic_DOM.text(""),
                    width: new TacoTable_Types.Fixed("2rem"),
                    headerJSX: Data_Maybe.Nothing.value
                } ])));
            };
        };
    };
};
var eqMyColumn = {
    eq: function (x) {
        return function (y) {
            if (x instanceof ColSubprojectCode && y instanceof ColSubprojectCode) {
                return true;
            };
            if (x instanceof ColCode && y instanceof ColCode) {
                return true;
            };
            if (x instanceof ColDescription && y instanceof ColDescription) {
                return true;
            };
            if (x instanceof ColRTCode && y instanceof ColRTCode) {
                return true;
            };
            if (x instanceof ColRtDescription && y instanceof ColRtDescription) {
                return true;
            };
            if (x instanceof ColAmount && y instanceof ColAmount) {
                return true;
            };
            if (x instanceof ColUnit && y instanceof ColUnit) {
                return true;
            };
            if (x instanceof ColPrice && y instanceof ColPrice) {
                return true;
            };
            if (x instanceof ColPriceUnits && y instanceof ColPriceUnits) {
                return true;
            };
            if (x instanceof ColTotal && y instanceof ColTotal) {
                return true;
            };
            if (x instanceof ColFlags && y instanceof ColFlags) {
                return true;
            };
            if (x instanceof ColGroup && y instanceof ColGroup) {
                return true;
            };
            if (x instanceof ColCheckbox && y instanceof ColCheckbox) {
                return true;
            };
            if (x instanceof ColTotalUnit && y instanceof ColTotalUnit) {
                return true;
            };
            if (x instanceof ColProject && y instanceof ColProject) {
                return true;
            };
            return false;
        };
    }
};
var getColumns = function (props) {
    return function (selectionProps) {
        var toggleFlagged = function (v) {
            if (v instanceof Types_Element.GroupFlagNone) {
                return Types_Element.GroupFlagFlag.value;
            };
            if (v instanceof Types_Element.GroupFlagFlag) {
                return Types_Element.GroupFlagNone.value;
            };
            if (v instanceof Types_Element.GroupFlagCheck) {
                return Types_Element.GroupFlagBoth.value;
            };
            if (v instanceof Types_Element.GroupFlagBoth) {
                return Types_Element.GroupFlagCheck.value;
            };
            throw new Error("Failed pattern match at CostEstimation.BuildingElementsView.Table (line 222, column 21 - line 226, column 38): " + [ v.constructor.name ]);
        };
        var toggleChecked = function (v) {
            if (v instanceof Types_Element.GroupFlagNone) {
                return Types_Element.GroupFlagCheck.value;
            };
            if (v instanceof Types_Element.GroupFlagFlag) {
                return Types_Element.GroupFlagBoth.value;
            };
            if (v instanceof Types_Element.GroupFlagCheck) {
                return Types_Element.GroupFlagNone.value;
            };
            if (v instanceof Types_Element.GroupFlagBoth) {
                return Types_Element.GroupFlagFlag.value;
            };
            throw new Error("Failed pattern match at CostEstimation.BuildingElementsView.Table (line 217, column 21 - line 221, column 37): " + [ v.constructor.name ]);
        };
        var testIdPrefix = function (v) {
            return function (id) {
                return Data_Show.show(Types_CostEstimation.showECViewMode)(props.viewMode) + ("-element-" + (Data_Show.show(Types_Element.showElementId)(v.elementId) + ("-" + id)));
            };
        };
        var onInputBlur = props.setSelectedInputColumn(Data_Maybe.Nothing.value);
        var mkOnFocus = function (col) {
            return function (elementId) {
                return function (idx) {
                    return function __do() {
                        Data_Monoid.guard(Effect.monoidEffect(Data_Monoid.monoidUnit))(Data_Eq.notEq(Data_Maybe.eqMaybe(Types_Element.eqElementId))(props.elementProps.detailsElementId)(new Data_Maybe.Just(elementId)))(props.elementProps.setDetailsElementIdx(false)(new Edited.Edited(new Data_Maybe.Just(idx))))();
                        return props.setSelectedInputColumn(new Data_Maybe.Just(col))();
                    };
                };
            };
        };
        var mkFlagIcon = function (icon) {
            return function (color) {
                return function (condition) {
                    return function (v) {
                        return TacoIcon.component()()()()({
                            icon: icon,
                            size: TacoConstants.XS.value,
                            color: (function () {
                                if (condition) {
                                    return color;
                                };
                                return TacoConstants.Transparent.value;
                            })()
                        });
                    };
                };
            };
        };
        var mkAction$prime = function (handler) {
            return function (elementId) {
                return function (editAction) {
                    return new Actions.EstimationElementActionRequest({
                        projectId: props.projectId,
                        params: new Types_Estimation.EditEstimationElement({
                            elementId: elementId,
                            editAction: editAction
                        }),
                        handler: handler
                    });
                };
            };
        };
        var mkAction = function (elementId) {
            return function (mkEditAction) {
                return function (value) {
                    return function (handler) {
                        return mkAction$prime(handler)(elementId)(mkEditAction(value));
                    };
                };
            };
        };
        var rtCodeCellInput = function (r) {
            return function (v) {
                return TacoInput.remoteStringField()()({
                    value: v.rtCode,
                    mkAction: mkAction(v.elementId)(function (rtCode) {
                        return new Types_Estimation.EditEstimationElementRtCode({
                            rtCode: rtCode
                        });
                    }),
                    onFocus: mkOnFocus(ColRTCode.value)(v.elementId)(r.idx),
                    onBlur: onInputBlur,
                    autoFocus: Data_Eq.eq(Data_Maybe.eqMaybe(eqMyColumn))(r.selectedRowColumn)(new Data_Maybe.Just(ColRTCode.value)),
                    validator: LengthValidator.lengthValidator(0)(40),
                    stateKey: Data_Show.show(Types_Element.showElementId)(v.elementId),
                    rendering: new TacoInput_Styles.TableCellRendering(TacoTable_Types.RowSmall.value),
                    testId: testIdPrefix(v)("rt-code-input")
                });
            };
        };
        var rtDescriptionCellInput = function (r) {
            return function (v) {
                return TacoInput.remoteStringField()()({
                    value: v.rtDescription,
                    title: v.rtDescription,
                    mkAction: mkAction(v.elementId)(function (rtDescription) {
                        return new Types_Estimation.EditEstimationElementRtDescription({
                            rtDescription: rtDescription
                        });
                    }),
                    onFocus: mkOnFocus(ColRtDescription.value)(v.elementId)(r.idx),
                    onBlur: onInputBlur,
                    autoFocus: Data_Eq.eq(Data_Maybe.eqMaybe(eqMyColumn))(r.selectedRowColumn)(new Data_Maybe.Just(ColRtDescription.value)),
                    validator: LengthValidator.lengthValidator(0)(255),
                    stateKey: Data_Show.show(Types_Element.showElementId)(v.elementId),
                    textarea: true,
                    expandWhenActive: true,
                    rendering: new TacoInput_Styles.TableCellRendering(TacoTable_Types.RowSmall.value),
                    testId: testIdPrefix(v)("rt-description-input")
                });
            };
        };
        var unitCellInput = function (r) {
            return function (v) {
                return TacoInput.remoteStringField()()({
                    value: v.unit,
                    mkAction: mkAction(v.elementId)(function (unit) {
                        return new Types_Estimation.EditEstimationElementUnit({
                            unit: unit
                        });
                    }),
                    onFocus: mkOnFocus(ColUnit.value)(v.elementId)(r.idx),
                    onBlur: onInputBlur,
                    autoFocus: Data_Eq.eq(Data_Maybe.eqMaybe(eqMyColumn))(r.selectedRowColumn)(new Data_Maybe.Just(ColUnit.value)),
                    validator: LengthValidator.lengthValidator(0)(10),
                    stateKey: Data_Show.show(Types_Element.showElementId)(v.elementId),
                    rendering: new TacoInput_Styles.TableCellRendering(TacoTable_Types.RowSmall.value),
                    testId: testIdPrefix(v)("unit-input")
                });
            };
        };
        var memoBtn = function (v) {
            return TacoButton.actionToggleButton({
                value: Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Data_String_Common["null"])(v.memo),
                icon: TacoIcon_Icons_Types.IconMemoIndicatorV1.value,
                colors: {
                    active: TacoConstants.ElementPurpleDark.value,
                    inactive: TacoConstants.Transparent.value
                },
                action: Data_Maybe.Nothing.value,
                title: v.memo,
                testId: testIdPrefix(v)("memo-indicator-button")
            });
        };
        var isFlagged = function (element) {
            var v = Types_Element.groupFlagFromInt(element.groupFlag);
            if (v instanceof Types_Element.GroupFlagFlag) {
                return true;
            };
            if (v instanceof Types_Element.GroupFlagBoth) {
                return true;
            };
            return false;
        };
        var isFlagFlagged = function (e) {
            var v = Types_Element.groupFlagFromInt(e.groupFlag);
            if (v instanceof Types_Element.GroupFlagFlag) {
                return true;
            };
            if (v instanceof Types_Element.GroupFlagBoth) {
                return true;
            };
            return false;
        };
        var isChecked = function (element) {
            var v = Types_Element.groupFlagFromInt(element.groupFlag);
            if (v instanceof Types_Element.GroupFlagCheck) {
                return true;
            };
            if (v instanceof Types_Element.GroupFlagBoth) {
                return true;
            };
            return false;
        };
        var isCheckFlagged = function (e) {
            var v = Types_Element.groupFlagFromInt(e.groupFlag);
            if (v instanceof Types_Element.GroupFlagCheck) {
                return true;
            };
            if (v instanceof Types_Element.GroupFlagBoth) {
                return true;
            };
            return false;
        };
        var hasMemoElement = function (v) {
            return mkFlagIcon(TacoIcon_Icons_Types.IconMemoIndicatorV1.value)(TacoConstants.Gray.value)(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Data_String_Common["null"])(v.memo))(testIdPrefix(v)("memo-indicator"));
        };
        var guardJSX = function (flag) {
            return function (jsx) {
                if (flag) {
                    return jsx;
                };
                return React_Basic.empty;
            };
        };
        var flagElement = function (v) {
            return mkFlagIcon(TacoIcon_Icons_Types.IconFlagV1.value)(TacoConstants.PrimaryBlue.value)(isFlagFlagged(v))(testIdPrefix(v)("flagged"));
        };
        var descriptionCellInput = function (r) {
            return function (v) {
                return TacoInput.remoteStringField()()({
                    value: v.description,
                    title: v.description,
                    mkAction: mkAction(v.elementId)(function (description) {
                        return new Types_Estimation.EditEstimationElementDescription({
                            description: description
                        });
                    }),
                    onFocus: mkOnFocus(ColDescription.value)(v.elementId)(r.idx),
                    onBlur: onInputBlur,
                    autoFocus: Data_Eq.eq(Data_Maybe.eqMaybe(eqMyColumn))(r.selectedRowColumn)(new Data_Maybe.Just(ColDescription.value)),
                    validator: LengthValidator.lengthValidator(0)(50),
                    stateKey: Data_Show.show(Types_Element.showElementId)(v.elementId),
                    rendering: new TacoInput_Styles.TableCellRendering(TacoTable_Types.RowSmall.value),
                    testId: testIdPrefix(v)("description-input")
                });
            };
        };
        var currencySymbol = EstimationUtils.getCurrencySymbol(props.projectCurrency);
        var componentGroupCodes = (function () {
            var $168 = Data_Functor.map(Data_Functor.functorArray)(Data_Newtype.un()(Types_ClassificationGroup.ClassificationGroupCode));
            return function ($169) {
                return $168(Types_BuildingElementsView.elementsDataComponentGroupCodes((function (v) {
                    return v.elementsData;
                })($169)));
            };
        })();
        var codeCellInput = function (r) {
            return function (v) {
                return TacoInput.remoteStringField()()({
                    value: Data_Newtype.un()(Types_Element.ElementCode)(v.code),
                    mkAction: mkAction(v.elementId)(function (code$prime) {
                        return new Types_Estimation.EditEstimationElementCode({
                            code: code$prime
                        });
                    }),
                    onFocus: mkOnFocus(ColCode.value)(v.elementId)(r.idx),
                    onBlur: onInputBlur,
                    autoFocus: Data_Eq.eq(Data_Maybe.eqMaybe(eqMyColumn))(r.selectedRowColumn)(new Data_Maybe.Just(ColCode.value)),
                    validator: LengthValidator.lengthValidator(0)(50),
                    stateKey: Data_Show.show(Types_Element.showElementId)(v.elementId),
                    rendering: new TacoInput_Styles.TableCellRendering(TacoTable_Types.RowSmall.value),
                    testId: testIdPrefix(v)("code-input")
                });
            };
        };
        var checkedElement = function (v) {
            return mkFlagIcon(TacoIcon_Icons_Types.IconCheckCircleV1.value)(TacoConstants.GreenLight.value)(isCheckFlagged(v))(testIdPrefix(v)("checked"));
        };
        var canEdit = EstimationUtils.canEditView(props);
        var checkedBtn = function (v) {
            return TacoButton.actionToggleButton({
                value: isChecked(v),
                icon: TacoIcon_Icons_Types.IconCheckCircleV1.value,
                colors: {
                    active: TacoConstants.GreenLight.value,
                    inactive: TacoConstants.Gray.value
                },
                action: Data_Monoid.guard(Data_Maybe.monoidMaybe(Effect.semigroupEffect(Data_Semigroup.semigroupUnit)))(canEdit)(Data_Maybe.Just.create(props.appDispatch(mkAction(v.elementId)(function (gf) {
                    return new Types_Estimation.EditEstimationElementGroupFlag({
                        groupFlag: toggleChecked(gf)
                    });
                })(Types_Element.groupFlagFromInt(v.groupFlag))(Control_Applicative.pure(Control_Applicative.applicativeFn)(Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit))))))),
                title: "",
                testId: testIdPrefix(v)("checked-button")
            });
        };
        var flaggedBtn = function (v) {
            return TacoButton.actionToggleButton({
                value: isFlagged(v),
                icon: TacoIcon_Icons_Types.IconFlagV1.value,
                colors: {
                    active: TacoConstants.PrimaryBlue.value,
                    inactive: TacoConstants.Gray.value
                },
                action: Data_Monoid.guard(Data_Maybe.monoidMaybe(Effect.semigroupEffect(Data_Semigroup.semigroupUnit)))(canEdit)(Data_Maybe.Just.create(props.appDispatch(mkAction(v.elementId)(function (gf) {
                    return new Types_Estimation.EditEstimationElementGroupFlag({
                        groupFlag: toggleFlagged(gf)
                    });
                })(Types_Element.groupFlagFromInt(v.groupFlag))(Control_Applicative.pure(Control_Applicative.applicativeFn)(Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit))))))),
                title: "",
                testId: testIdPrefix(v)("flagged-button")
            });
        };
        var flagButtons = function (element) {
            return Box.box("flagButtonsWrapper")([ Box.FlexRow.value, Box.JustifyCenter.value, Box.MarginTop.create(new TacoConstants.CustomSize("1px")) ])([ checkedBtn(element), flaggedBtn(element), memoBtn(element) ]);
        };
        var flagsCellInput = function (element) {
            return Box.box("flagInputs")([ Box.FlexColumn.value ])([ flagButtons(element) ]);
        };
        var subprojectInput = function (r) {
            return function (v) {
                return CostEstimation_AddComponent.mkSubprojectInput({
                    subprojects: props.subprojects,
                    currentId: Data_Nullable.toMaybe(v.subprojectId),
                    label: Data_Maybe.Nothing.value,
                    disabled: !canEdit,
                    onChange: function (subprojectId) {
                        var $134 = Data_Eq.eq(Data_Maybe.eqMaybe(Types_Project.eqSubprojectId))(Data_Nullable.toMaybe(v.subprojectId))(new Data_Maybe.Just(subprojectId));
                        if ($134) {
                            return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                        };
                        var editParams = new Types_Estimation.EditEstimationElement({
                            editAction: new Types_Estimation.EditEstimationElementSubprojectId({
                                subprojectId: subprojectId
                            }),
                            elementId: v.elementId
                        });
                        return props.appDispatch(new Actions.EstimationElementActionRequest({
                            projectId: props.projectId,
                            params: editParams,
                            handler: function (v1) {
                                return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                            }
                        }));
                    },
                    onFocus: mkOnFocus(ColSubprojectCode.value)(v.elementId)(r.idx),
                    onBlur: onInputBlur,
                    autoFocus: Data_Eq.eq(Data_Maybe.eqMaybe(eqMyColumn))(r.selectedRowColumn)(new Data_Maybe.Just(ColSubprojectCode.value)),
                    rendering: new TacoInput_Styles.TableCellRendering(TacoTable_Types.RowSmall.value),
                    testId: "element-" + Data_Show.show(Types_Element.showElementId)(v.elementId)
                })(Data_Maybe.Nothing.value);
            };
        };
        var amountCellInput = function (r) {
            return function (ed) {
                var el = Data_Newtype.un()(Types_Element.WasmEstimationElement)(ed.element);
                return Box.box("amountInput")([ Box.FlexRow.value, Box.Width.create(new TacoConstants.CustomSize("100%")), Box.Style.create(React_Basic_Emotion.css()({
                    flexWrap: React_Basic_Emotion.str("nowrap")
                })) ])([ (function () {
                    if (r.activeDixonElementLinks instanceof Data_Maybe.Just) {
                        var linkTypes = Data_Array.groupBy(Data_Function.on(Data_Eq.eq(Data_Eq.eqString))(function (v) {
                            return v.from_type;
                        }))(Data_Array.sortWith(Data_Ord.ordString)(function (v) {
                            return v.from_type;
                        })(r.activeDixonElementLinks.value0));
                        return Box.box("linkTypeWrapper")([ Box.PaddingTop.create(new TacoConstants.CustomSize("3px")) ])(Control_Applicative.pure(Control_Applicative.applicativeArray)(TacoText.component()()({
                            wrap: TacoText_Types.NoWrap.value,
                            text: (function () {
                                var v = Data_Functor.map(Data_Maybe.functorMaybe)(Data_Functor.map(Data_Functor.functorFn)(function (v2) {
                                    return v2.from_type;
                                })(Data_Array_NonEmpty.head))(Data_Array.head(linkTypes));
                                var v1 = Data_Array.length(linkTypes);
                                if (v1 === 0) {
                                    return "";
                                };
                                if (v1 === 1 && (v instanceof Data_Maybe.Just && v.value0 === "Formula")) {
                                    return "(\u0192)";
                                };
                                if (v1 === 1 && (v instanceof Data_Maybe.Just && v.value0 === "Area")) {
                                    return "(A)";
                                };
                                if (v1 === 1 && (v instanceof Data_Maybe.Just && v.value0 === "Perimeter")) {
                                    return "(P)";
                                };
                                if (v1 === 1 && (v instanceof Data_Maybe.Just && v.value0 === "NumVertices")) {
                                    return "(N)";
                                };
                                return "(...)";
                            })()
                        })));
                    };
                    if (r.activeDixonElementLinks instanceof Data_Maybe.Nothing) {
                        return React_Basic.empty;
                    };
                    throw new Error("Failed pattern match at CostEstimation.BuildingElementsView.Table (line 358, column 9 - line 373, column 30): " + [ r.activeDixonElementLinks.constructor.name ]);
                })(), (function () {
                    var v = Data_Array.head(ed.locations);
                    if (v instanceof Data_Maybe.Just) {
                        return TacoInput.remoteNumberField()()({
                            value: ed.amount,
                            mkAction: mkAction(el.elementId)(function (amount) {
                                return new Types_Estimation.EditEstimationElementAmountForSingleLocation({
                                    elementLocationId: v.value0.elementLocationId,
                                    amount: amount
                                });
                            }),
                            onFocus: mkOnFocus(ColAmount.value)(el.elementId)(r.idx),
                            onBlur: onInputBlur,
                            autoFocus: Data_Eq.eq(Data_Maybe.eqMaybe(eqMyColumn))(r.selectedRowColumn)(new Data_Maybe.Just(ColAmount.value)),
                            stateKey: Data_Show.show(Types_Element.showElementId)(el.elementId),
                            disabled: Data_Array.length(ed.locations) !== 1,
                            align: TacoInput.RightAlign.value,
                            rendering: new TacoInput_Styles.TableCellRendering(TacoTable_Types.RowSmall.value),
                            testId: testIdPrefix(ed.element)("amount-input")
                        });
                    };
                    return TacoInput.numberInput()()(Data_Eq.eqNumber)({
                        value: ed.amount,
                        precision: 1,
                        disabled: Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Data_Array["null"])(ed.locations),
                        onSave: function (amount) {
                            return Data_Monoid.guard(Effect.monoidEffect(Data_Monoid.monoidUnit))(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Utils.nearZero)(amount) && amount !== ed.amount)(props.appDispatch(mkAction$prime(Data_Monoid.mempty(Data_Monoid.monoidFn(Effect.monoidEffect(Data_Monoid.monoidUnit))))(el.elementId)(new Types_Estimation.AddEstimationElementAmountForWildcardLocation({
                                amount: amount
                            }))));
                        },
                        align: TacoInput.RightAlign.value,
                        rendering: new TacoInput_Styles.TableCellRendering(TacoTable_Types.RowSmall.value),
                        testId: testIdPrefix(ed.element)("amount-input")
                    });
                })() ]);
            };
        };
        var _unEl = (function () {
            var $170 = Data_Newtype.un()(Types_BuildingElementsView.WasmElementsData);
            return function ($171) {
                return $170((function (v) {
                    return v.elementsData;
                })($171));
            };
        })();
        var isTopicRow = function (r) {
            return (_unEl(r)).isTopicRow;
        };
        var _rowToEl = function ($172) {
            return (function (v) {
                return v.element;
            })(_unEl($172));
        };
        var amountCell = (function () {
            if (canEdit) {
                return new TacoTable_Types.JSXCell([ TacoTable_Types.Numeric.value, TacoTable_Types.Input.value ], function (r) {
                    return guardJSX(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(isTopicRow)(r))(amountCellInput(r)(_unEl(r)));
                });
            };
            return new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], function (r) {
                var $147 = Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(isTopicRow)(r);
                if ($147) {
                    return TacoText.component()()({
                        text: Formatters.formatDecimalValue(1)((_unEl(r)).amount),
                        testId: testIdPrefix(_rowToEl(r))("amount")
                    });
                };
                return React_Basic.empty;
            });
        })();
        var flagsCell = (function () {
            if (canEdit) {
                return new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], function (r) {
                    return guardJSX(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(isTopicRow)(r))(flagsCellInput(_rowToEl(r)));
                });
            };
            return new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], function (r) {
                return guardJSX(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(isTopicRow)(r))(Gap.gapX(TacoConstants.XS.value)(Data_Functor.flap(Data_Functor.functorArray)([ checkedElement, flagElement, hasMemoElement ])(_rowToEl(r))));
            });
        })();
        var groupCell = function (r) {
            return TacoText.component()()({
                text: Data_Foldable.intercalate(Data_Foldable.foldableArray)(Data_Monoid.monoidString)(", ")(componentGroupCodes(r)),
                testId: testIdPrefix(_rowToEl(r))("groups")
            });
        };
        var priceCell = function (r) {
            var $149 = Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(isTopicRow)(r);
            if ($149) {
                return TacoText.component()()({
                    text: Formatters.formatDecimalValue(2)((_unEl(r)).unitPrice),
                    testId: testIdPrefix(_rowToEl(r))("price")
                });
            };
            return React_Basic.empty;
        };
        var projectCell = function (r) {
            return TacoText.component()()({
                text: CostEstimation_EstimationFunctions.projectCodeAndName(r.associatedProject),
                title: CostEstimation_EstimationFunctions.projectCodeAndName(r.associatedProject),
                testId: testIdPrefix(_rowToEl(r))("project")
            });
        };
        var subprojectCodeCell = (function () {
            if (canEdit) {
                return new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], function (r) {
                    return guardJSX(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(isTopicRow)(r))(subprojectInput(r)(_rowToEl(r)));
                });
            };
            return new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], function (r) {
                return TacoText.component()()({
                    text: (function (v) {
                        return v.subprojectCode;
                    })(_unEl(r)),
                    testId: testIdPrefix(_rowToEl(r))("subproject-code")
                });
            });
        })();
        var totalCell = function (r) {
            return TacoText.component()()({
                text: Formatters.formatDecimalValue(2)((function (v) {
                    return v.totalPrice;
                })(_unEl(r))),
                testId: testIdPrefix(_rowToEl(r))("total")
            });
        };
        var totalUnitCell = function (r) {
            return TacoText.component()()({
                text: currencySymbol,
                testId: testIdPrefix(_rowToEl(r))("total-unit")
            });
        };
        var _e = Data_Newtype.un()(Types_Element.WasmEstimationElement);
        var checkbox = function (v) {
            return TacoCheckbox.component()()({
                className: "toggle",
                isChecked: v.checked,
                onToggle: new TacoCheckbox.WithShift(function (mShift) {
                    if (mShift instanceof Data_Maybe.Just && mShift.value0) {
                        return props.toggleAreaOfCheckboxes((function (v1) {
                            return v1.elementId;
                        })(_e(v.elementsData.element)));
                    };
                    return props.toggleElementChecked((function (v1) {
                        return v1.elementId;
                    })(_e(v.elementsData.element)));
                }),
                labelText: Data_Maybe.Nothing.value,
                testId: "element-" + (Data_Show.show(Types_Element.showElementId)((function (v1) {
                    return v1.elementId;
                })(_e(v.elementsData.element))) + "-selected-checkbox"),
                color: TacoConstants.elementActionColors.border
            });
        };
        var codeCell = (function () {
            if (canEdit) {
                return new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], function (r) {
                    return guardJSX(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(isTopicRow)(r))(codeCellInput(r)(_rowToEl(r)));
                });
            };
            return new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], function (r) {
                return TacoText.component()()({
                    text: Data_Newtype.unwrap()((function (v) {
                        return v.code;
                    })(_e(_rowToEl(r)))),
                    testId: testIdPrefix(_rowToEl(r))("code")
                });
            });
        })();
        var descriptionCell = (function () {
            if (canEdit) {
                return new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], function (r) {
                    return guardJSX(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(isTopicRow)(r))(descriptionCellInput(r)(_rowToEl(r)));
                });
            };
            return new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], function (r) {
                return TacoText.component()()({
                    text: (function (v) {
                        return v.description;
                    })(_e(_rowToEl(r))),
                    testId: testIdPrefix(_rowToEl(r))("description")
                });
            });
        })();
        var priceUnitsCell = function (r) {
            return TacoText.component()()({
                text: currencySymbol + "/" + (function (v) {
                    return v.unit;
                })(_e((function (v) {
                    return v.element;
                })(_unEl(r)))),
                testId: testIdPrefix(_rowToEl(r))("price-unit")
            });
        };
        var rtCodeCell = (function () {
            if (canEdit) {
                return new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], function (r) {
                    return guardJSX(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(isTopicRow)(r))(rtCodeCellInput(r)(_rowToEl(r)));
                });
            };
            return new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], function (r) {
                return TacoText.component()()({
                    text: (function (v) {
                        return v.rtCode;
                    })(_e((function (v) {
                        return v.element;
                    })(_unEl(r)))),
                    testId: testIdPrefix(_rowToEl(r))("rt-code")
                });
            });
        })();
        var rtDescriptionCell = (function () {
            if (canEdit) {
                return new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], function (r) {
                    var $161 = Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(isTopicRow)(r);
                    if ($161) {
                        return rtDescriptionCellInput(r)(_rowToEl(r));
                    };
                    return TacoText.component()()({
                        text: (_e(_rowToEl(r))).rtDescription,
                        weight: TacoText_Types.Bold.value,
                        title: (_e(_rowToEl(r))).rtDescription,
                        testId: testIdPrefix(_rowToEl(r))("rt-description")
                    });
                });
            };
            return new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], function (r) {
                return TacoText.component()()({
                    text: (function (v) {
                        return v.rtDescription;
                    })(_e((function (v) {
                        return v.element;
                    })(_unEl(r)))),
                    title: (_e(_rowToEl(r))).rtDescription,
                    testId: testIdPrefix(_rowToEl(r))("rt-description")
                });
            });
        })();
        var unitCell = (function () {
            if (canEdit) {
                return new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], function (r) {
                    return guardJSX(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(isTopicRow)(r))(unitCellInput(r)(_rowToEl(r)));
                });
            };
            return new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], function (r) {
                return TacoText.component()()({
                    text: (function (v) {
                        return v.unit;
                    })(_e((function (v) {
                        return v.element;
                    })(_unEl(r)))),
                    testId: testIdPrefix(_rowToEl(r))("unit")
                });
            });
        })();
        return prepareColumns(props.viewMode)(props)(selectionProps)(function (col) {
            if (col instanceof ColCheckbox) {
                return {
                    sortProperty: Data_Maybe.Nothing.value,
                    cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], function (r) {
                        return guardJSX(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(isTopicRow)(r))(checkbox(r));
                    })
                };
            };
            if (col instanceof ColFlags) {
                return {
                    sortProperty: Data_Maybe.Nothing.value,
                    cell: flagsCell
                };
            };
            if (col instanceof ColGroup) {
                return {
                    sortProperty: Data_Maybe.Nothing.value,
                    cell: new TacoTable_Types.JSXCell([  ], groupCell)
                };
            };
            if (col instanceof ColSubprojectCode) {
                return {
                    sortProperty: new Data_Maybe.Just(BySubprojectCode.value),
                    cell: subprojectCodeCell
                };
            };
            if (col instanceof ColCode) {
                return {
                    sortProperty: new Data_Maybe.Just(ByCode.value),
                    cell: codeCell
                };
            };
            if (col instanceof ColDescription) {
                return {
                    sortProperty: new Data_Maybe.Just(ByDescription.value),
                    cell: descriptionCell
                };
            };
            if (col instanceof ColRTCode) {
                return {
                    sortProperty: new Data_Maybe.Just(ByRTCode.value),
                    cell: rtCodeCell
                };
            };
            if (col instanceof ColRtDescription) {
                return {
                    sortProperty: new Data_Maybe.Just(ByRtDescription.value),
                    cell: rtDescriptionCell
                };
            };
            if (col instanceof ColAmount) {
                return {
                    sortProperty: new Data_Maybe.Just(ByAmount.value),
                    cell: amountCell
                };
            };
            if (col instanceof ColUnit) {
                return {
                    sortProperty: new Data_Maybe.Just(ByUnit.value),
                    cell: unitCell
                };
            };
            if (col instanceof ColPrice) {
                return {
                    sortProperty: Data_Maybe.Nothing.value,
                    cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Numeric.value ], priceCell)
                };
            };
            if (col instanceof ColPriceUnits) {
                return {
                    sortProperty: new Data_Maybe.Just(ByPrice.value),
                    cell: new TacoTable_Types.JSXCell([  ], priceUnitsCell)
                };
            };
            if (col instanceof ColTotal) {
                return {
                    sortProperty: new Data_Maybe.Just(ByTotal.value),
                    cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Numeric.value ], totalCell)
                };
            };
            if (col instanceof ColTotalUnit) {
                return {
                    sortProperty: Data_Maybe.Nothing.value,
                    cell: new TacoTable_Types.JSXCell([  ], totalUnitCell)
                };
            };
            if (col instanceof ColProject) {
                return {
                    sortProperty: Data_Maybe.Nothing.value,
                    cell: new TacoTable_Types.JSXCell([  ], projectCell)
                };
            };
            throw new Error("Failed pattern match at CostEstimation.BuildingElementsView.Table (line 78, column 62 - line 108, column 62): " + [ col.constructor.name ]);
        });
    };
};
var eqElementsTableSortProperty = {
    eq: function (x) {
        return function (y) {
            if (x instanceof BySubprojectCode && y instanceof BySubprojectCode) {
                return true;
            };
            if (x instanceof ByCode && y instanceof ByCode) {
                return true;
            };
            if (x instanceof ByDescription && y instanceof ByDescription) {
                return true;
            };
            if (x instanceof ByRTCode && y instanceof ByRTCode) {
                return true;
            };
            if (x instanceof ByRtDescription && y instanceof ByRtDescription) {
                return true;
            };
            if (x instanceof ByAmount && y instanceof ByAmount) {
                return true;
            };
            if (x instanceof ByUnit && y instanceof ByUnit) {
                return true;
            };
            if (x instanceof ByPrice && y instanceof ByPrice) {
                return true;
            };
            if (x instanceof ByTotal && y instanceof ByTotal) {
                return true;
            };
            return false;
        };
    }
};
var ordElementsTableSortProperty = {
    compare: function (x) {
        return function (y) {
            if (x instanceof BySubprojectCode && y instanceof BySubprojectCode) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof BySubprojectCode) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof BySubprojectCode) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByCode && y instanceof ByCode) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByCode) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByCode) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByDescription && y instanceof ByDescription) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByDescription) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByDescription) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByRTCode && y instanceof ByRTCode) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByRTCode) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByRTCode) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByRtDescription && y instanceof ByRtDescription) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByRtDescription) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByRtDescription) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByAmount && y instanceof ByAmount) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByAmount) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByAmount) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByUnit && y instanceof ByUnit) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByUnit) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByUnit) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByPrice && y instanceof ByPrice) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByPrice) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByPrice) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByTotal && y instanceof ByTotal) {
                return Data_Ordering.EQ.value;
            };
            throw new Error("Failed pattern match at CostEstimation.BuildingElementsView.Table (line 481, column 1 - line 481, column 78): " + [ x.constructor.name, y.constructor.name ]);
        };
    },
    Eq0: function () {
        return eqElementsTableSortProperty;
    }
};
var elementsTableStyleSet = CostEstimation_Styles.costEstimationTableStyleSet(TacoConstants.elementColors);
var elementsTableOptimized = TacoTable.tableOptimized(getElementsTableSortProperty)(eqElementsTableSortProperty)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
    reflectSymbol: function () {
        return "shortenReferenceProjectDetails";
    }
})(Data_Eq.eqBoolean))()({
    reflectSymbol: function () {
        return "selectedRowColumn";
    }
})(Data_Maybe.eqMaybe(eqMyColumn)))()({
    reflectSymbol: function () {
        return "idx";
    }
})(Data_Eq.eqInt))()({
    reflectSymbol: function () {
        return "elementsData";
    }
})(Types_BuildingElementsView.eqWasmElementsData))()({
    reflectSymbol: function () {
        return "dixonElementLinks";
    }
})(Data_Maybe.eqMaybe(Data_Eq.eqArray(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
    reflectSymbol: function () {
        return "target_field";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "measurement_group_id";
    }
})(Data_Eq.eqInt))()({
    reflectSymbol: function () {
        return "id";
    }
})(Data_Eq.eqInt))()({
    reflectSymbol: function () {
        return "from_type";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "element_spec_id";
    }
})(Data_Maybe.eqMaybe(Types_Newtypes.eqElementSpecId)))()({
    reflectSymbol: function () {
        return "component_id";
    }
})(Data_Maybe.eqMaybe(Types_Component.eqComponentId)))()({
    reflectSymbol: function () {
        return "building_element_id";
    }
})(Data_Maybe.eqMaybe(Types_Element.eqElementId)))))))()({
    reflectSymbol: function () {
        return "columnSizeOffsets";
    }
})(Data_Maybe.eqMaybe(Data_Map_Internal.eqMap(Data_Eq.eqString)(Data_Eq.eqNumber))))()({
    reflectSymbol: function () {
        return "checked";
    }
})(Data_Eq.eqBoolean))()({
    reflectSymbol: function () {
        return "associatedProject";
    }
})(Data_Maybe.eqMaybe(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
    reflectSymbol: function () {
        return "version";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "subprojects";
    }
})(Data_Eq.eqArray(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
    reflectSymbol: function () {
        return "projectId";
    }
})(Types_Project.eqProjectId))()({
    reflectSymbol: function () {
        return "name";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "id";
    }
})(Types_Project.eqSubprojectId))()({
    reflectSymbol: function () {
        return "countInMeasurementTotal";
    }
})(Data_Eq.eqBoolean))()({
    reflectSymbol: function () {
        return "code";
    }
})(Data_Eq.eqString)))))()({
    reflectSymbol: function () {
        return "state";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "projectGroup";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "programType";
    }
})(Data_Eq.eqInt))()({
    reflectSymbol: function () {
        return "name";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "isReportingProject";
    }
})(Data_Eq.eqBoolean))()({
    reflectSymbol: function () {
        return "id";
    }
})(Types_Project.eqProjectId))()({
    reflectSymbol: function () {
        return "currency";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "createdDate";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "code";
    }
})(Types_Project.eqProjectCode))()({
    reflectSymbol: function () {
        return "applicationMode";
    }
})(Data_Eq.eqString)))))()({
    reflectSymbol: function () {
        return "active_measurement_groups";
    }
})(Data_Maybe.eqMaybe(Data_Eq.eqArray(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
    reflectSymbol: function () {
        return "unit";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "perimeter";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "num_vertices";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "name";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "linked_component_ids";
    }
})(Data_Eq.eqArray(Types_Component.eqComponentId)))()({
    reflectSymbol: function () {
        return "linked_building_element_ids";
    }
})(Data_Eq.eqArray(Types_Element.eqElementId)))()({
    reflectSymbol: function () {
        return "id";
    }
})(Data_Eq.eqInt))()({
    reflectSymbol: function () {
        return "formula_sum";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "drawing_ids";
    }
})(Data_Eq.eqArray(Data_Eq.eqInt)))()({
    reflectSymbol: function () {
        return "default_location";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "default_formula";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "color";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "code";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "area";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "active";
    }
})(Data_Eq.eqBoolean))))))()({
    reflectSymbol: function () {
        return "activeDixonElementLinks";
    }
})(Data_Maybe.eqMaybe(Data_Eq.eqArray(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
    reflectSymbol: function () {
        return "target_field";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "measurement_group_id";
    }
})(Data_Eq.eqInt))()({
    reflectSymbol: function () {
        return "id";
    }
})(Data_Eq.eqInt))()({
    reflectSymbol: function () {
        return "from_type";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "element_spec_id";
    }
})(Data_Maybe.eqMaybe(Types_Newtypes.eqElementSpecId)))()({
    reflectSymbol: function () {
        return "component_id";
    }
})(Data_Maybe.eqMaybe(Types_Component.eqComponentId)))()({
    reflectSymbol: function () {
        return "building_element_id";
    }
})(Data_Maybe.eqMaybe(Types_Element.eqElementId))))))));
module.exports = {
    getColumns: getColumns,
    getRow: getRow,
    BySubprojectCode: BySubprojectCode,
    ByCode: ByCode,
    ByDescription: ByDescription,
    ByRTCode: ByRTCode,
    ByRtDescription: ByRtDescription,
    ByAmount: ByAmount,
    ByUnit: ByUnit,
    ByPrice: ByPrice,
    ByTotal: ByTotal,
    getSortingValue: getSortingValue,
    elementsTableOptimized: elementsTableOptimized,
    elementsTableStyleSet: elementsTableStyleSet,
    ColSubprojectCode: ColSubprojectCode,
    ColCode: ColCode,
    ColDescription: ColDescription,
    ColRTCode: ColRTCode,
    ColRtDescription: ColRtDescription,
    ColAmount: ColAmount,
    ColUnit: ColUnit,
    ColPrice: ColPrice,
    ColPriceUnits: ColPriceUnits,
    ColTotal: ColTotal,
    ColFlags: ColFlags,
    ColGroup: ColGroup,
    ColCheckbox: ColCheckbox,
    ColTotalUnit: ColTotalUnit,
    ColProject: ColProject,
    getColumnSizeOffset: getColumnSizeOffset,
    prepareColumns: prepareColumns,
    eqElementsTableSortProperty: eqElementsTableSortProperty,
    ordElementsTableSortProperty: ordElementsTableSortProperty,
    getElementsTableSortProperty: getElementsTableSortProperty,
    eqMyColumn: eqMyColumn
};
