// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
var Actions = require("../Actions/index.js");
var Box = require("../Box/index.js");
var Client_WASM = require("../Client.WASM/index.js");
var Common = require("../Common/index.js");
var Constants = require("../Constants/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Controls = require("../Controls/index.js");
var CostEstimation_ProductionPlanningWarning = require("../CostEstimation.ProductionPlanningWarning/index.js");
var CostEstimation_Types = require("../CostEstimation.Types/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Set = require("../Data.Set/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var EstimationComponentFilter = require("../EstimationComponentFilter/index.js");
var EstimationExcelExportButton = require("../EstimationExcelExportButton/index.js");
var EstimationUtils = require("../EstimationUtils/index.js");
var FeatureHooks = require("../FeatureHooks/index.js");
var FetchHooks = require("../FetchHooks/index.js");
var FetchMiddleware = require("../FetchMiddleware/index.js");
var FileUpload = require("../FileUpload/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var SocialExpensesControls = require("../SocialExpensesControls/index.js");
var StateHooks = require("../StateHooks/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoCheckbox = require("../TacoCheckbox/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoDropdown = require("../TacoDropdown/index.js");
var TacoDropdown_Types = require("../TacoDropdown.Types/index.js");
var TacoIcon_Icons_Types = require("../TacoIcon.Icons.Types/index.js");
var TacoInput = require("../TacoInput/index.js");
var TacoSearchInfo = require("../TacoSearchInfo/index.js");
var TacoSelect = require("../TacoSelect/index.js");
var TacoText = require("../TacoText/index.js");
var TacoText_Types = require("../TacoText.Types/index.js");
var TargetCostGroupsControls = require("../TargetCostGroupsControls/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_ComponentsView = require("../Types.ComponentsView/index.js");
var Types_CostEstimation = require("../Types.CostEstimation/index.js");
var Types_Newtypes = require("../Types.Newtypes/index.js");
var Types_UploadStatus = require("../Types.UploadStatus/index.js");
var UseLicenseFeature = require("../UseLicenseFeature/index.js");
var Utils = require("../Utils/index.js");
var WasmWorker = require("../WasmWorker/index.js");
var WasmWorker_WorkerRequest = require("../WasmWorker.WorkerRequest/index.js");
var WorkAchievementControls = require("../WorkAchievementControls/index.js");
var templateDownloadLink = TacoButton.component()()({
    text: "Lataa m\xe4\xe4r\xe4luettelo malli-excel",
    icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconFileDownloadV1.value),
    align: TacoButton_Types.Left.value,
    href: new Data_Maybe.Just(Constants.estimationComponentsUploadTemplate),
    testId: "get-estimation-components-upload-template-button"
});
var selectionControls = TofuHooks.mkHookComponent("SelectionControls")(function (props) {
    var mkShortenProjectDetailsCheckbox = function (v) {
        return TacoCheckbox.component()()({
            className: "shorten-project-details",
            isChecked: !v.shortenReferenceProjectDetails,
            onToggle: TacoCheckbox.NoEvent.create(v.setShortenProjectDetails(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean))),
            labelText: Data_Maybe.Just.create(new TacoCheckbox.StringLabel("N\xe4yt\xe4 projektitiedot")),
            testId: "shorten-project-details"
        });
    };
    return Control_Applicative.pure(Effect.applicativeEffect)(React_Basic.fragment([ Data_Maybe.maybe(React_Basic.empty)(mkShortenProjectDetailsCheckbox)(props.referenceModeFollowOptions), Data_Maybe.maybe(React_Basic.empty)(function (v) {
        return v.refProjectsModalButton;
    })(props.referenceModeFollowOptions) ]));
});
var mkEstimationUpload = function (r) {
    return TofuHooks.mkHookComponent(r.name)(function (v) {
        return function __do() {
            var v1 = TofuHooks.useState(Types_UploadStatus.Standby.value)();
            var v2 = TofuHooks.useState(Data_Maybe.Nothing.value)();
            var appDispatch = StateHooks.useDispatch();
            var uploadLabel = FileUpload.uploadFileLabel({
                filename: v2.value0,
                className: "",
                uniqueId: r.id,
                label: r.label
            });
            var uploadInput = FileUpload.mkFileUpload({
                projectId: new Data_Maybe.Just(v.projectId),
                uploadType: r.uploadType,
                endpointUrl: r.endpointUrl,
                uniqueId: r.id,
                uploadStatus: v1.value0,
                setUploadStatus: v1.value1,
                filename: v2.value0,
                setFilename: v2.value1,
                onSuccess: Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(function __do() {
                    FetchMiddleware["refreshEstimationProjectsData'"](appDispatch)(v.projectId)();
                    return r.onUploadSuccess(appDispatch)(v.projectId)();
                })
            });
            return React_Basic_DOM_Generated.div()({
                className: "flex-centered",
                children: [ Data_Maybe.fromMaybe(React_Basic.empty)(r.mTemplateDownloadLink), uploadInput, uploadLabel, FileUpload.uploadStatusToJSX(v1.value0) ]
            });
        };
    });
};
var uploadEstimationComponents = mkEstimationUpload({
    name: "UploadEstimationComponents",
    id: "components-upload-input",
    label: "Tuo m\xe4\xe4r\xe4luettelo",
    uploadType: FileUpload.EstimationComponentsUpload.value,
    endpointUrl: "/uploadEstimationComponents",
    mTemplateDownloadLink: new Data_Maybe.Just(templateDownloadLink),
    onUploadSuccess: Data_Monoid.mempty(Data_Monoid.monoidFn(Data_Monoid.monoidFn(Effect.monoidEffect(Data_Monoid.monoidUnit))))
});
var uploadEstimationQuantityByCostClass = mkEstimationUpload({
    name: "UploadEstimationQuantityByCostClass",
    id: "quantity-by-costClass-upload-input",
    label: "Tuo suoritteen panoslajien kustannukset",
    uploadType: FileUpload.EstimationQuantityByCostClassUpload.value,
    endpointUrl: "/uploadEstimationQuantityByCostClass",
    mTemplateDownloadLink: Data_Maybe.Nothing.value,
    onUploadSuccess: function (v) {
        return function (projectId) {
            return function __do() {
                WasmWorker.sendWasmWorkerRequest(new WasmWorker_WorkerRequest.RefreshSubprojects({
                    request_params: projectId
                }))();
                return WasmWorker.sendWasmWorkerRequest(new WasmWorker_WorkerRequest.RefreshEstimationCostClasses({
                    request_params: projectId
                }))();
            };
        };
    }
});
var uploadEstimationQuantityByLocation = mkEstimationUpload({
    name: "UploadEstimationQuantityByLocation",
    id: "quantity-by-location-upload-input",
    label: "Tuo m\xe4\xe4r\xe4luettelo, m\xe4\xe4r\xe4t sijainneittain",
    uploadType: FileUpload.EstimationQuantityByLocationUpload.value,
    endpointUrl: "/uploadEstimationQuantityByLocation",
    mTemplateDownloadLink: Data_Maybe.Nothing.value,
    onUploadSuccess: function (v) {
        return function (projectId) {
            return function __do() {
                WasmWorker.sendWasmWorkerRequest(new WasmWorker_WorkerRequest.RefreshSubprojects({
                    request_params: projectId
                }))();
                return WasmWorker.sendWasmWorkerRequest(new WasmWorker_WorkerRequest.RefreshProjectLocations({
                    request_params: projectId
                }))();
            };
        };
    }
});
var filterControls = TofuHooks.mkHookComponent("FilterControls")(function (props) {
    var componentFilter = EstimationComponentFilter.estimationComponentFilter({
        activeFilters: props.activeFilters,
        onSetActiveFilters: props.onSetActiveFilters,
        viewMode: props.viewMode,
        showTargetCostGroup: props.showTargetCostGroup
    });
    return Control_Applicative.pure(Effect.applicativeEffect)(componentFilter);
});
var controls = TofuHooks.mkMemoHookComponent("ViewControls")(function (v) {
    return function __do() {
        var appDispatch = StateHooks.useDispatch();
        var v1 = FetchHooks.useProjectCostGroupClassificationInfos(v.projectId)();
        var v2 = Client_WASM.useAdminetIntegrationActive();
        var v3 = TofuHooks.useState(false)();
        var procumentCSVEnabled = FeatureHooks.useFeatureEnabled("procumentCsv")();
        var exactSearchEnabled = FeatureHooks.useFeatureEnabled("component-exact-search")();
        var subprojectsEnabled = UseLicenseFeature.useLicenseFeature(UseLicenseFeature.Subprojects.value)();
        var componentGroupsEnabled = UseLicenseFeature.useLicenseFeature(UseLicenseFeature.ComponentGroups.value)();
        var locationsEnabled = UseLicenseFeature.useLicenseFeature(UseLicenseFeature.Locations.value)();
        var showCostClassColumnsItems = (function () {
            if (v.showCostClassColumns) {
                return Data_Functor.mapFlipped(Data_Functor.functorArray)(v.costClasses)(function (cc) {
                    var isChecked = Data_Set.member(Types_Newtypes.ordCostClassCode)(cc.costClassCode)(v.seletedShowCostClassCodes);
                    return TacoCheckbox.component()()({
                        isChecked: isChecked,
                        onToggle: TacoCheckbox.NoEvent.create(v.setSeletedShowCostClassCodes(Utils.toggleInSet(Types_Newtypes.ordCostClassCode)(cc.costClassCode))),
                        labelText: Data_Maybe.Just.create(TacoCheckbox.StringLabel.create("N\xe4yt\xe4 PL " + Data_Newtype.unwrap()(cc.costClassCode))),
                        testId: "show-cost-class-code-" + (Data_Newtype.unwrap()(cc.costClassCode) + "-toggle")
                    });
                });
            };
            return Common.emptyArray;
        })();
        var toggleControls = Control_Applicative.pure(Control_Applicative.applicativeArray)({
            title: new Data_Maybe.Just("N\xe4yt\xe4"),
            items: Data_Semigroup.append(Data_Semigroup.semigroupArray)([ (function () {
                if (v.showSocialExpensesToggleControl) {
                    return SocialExpensesControls.component({
                        isSocialExpensesToggled: v.showSocialExpensesInCosts,
                        onToggle: v.onSocialExpensesToggle,
                        projectId: v.projectId,
                        disabled: Data_Maybe.Nothing.value
                    });
                };
                return React_Basic.empty;
            })(), (function () {
                if (v.showWorkAchievementToggleControl) {
                    return WorkAchievementControls.component({
                        isWorkAchievementToggled: v.showWorkAchievement,
                        onToggle: v.onWorkAchievementToggle,
                        projectId: v.projectId,
                        disabled: Data_Maybe.Nothing.value
                    });
                };
                return React_Basic.empty;
            })(), (function () {
                if (v.showTargetCostGroupControl) {
                    return TargetCostGroupsControls.targetCostGroupsControls({
                        showTargetCostGroup: v.showTargetCostGroup,
                        onTargetCostGroupToggle: v.onTargetCostGroupToggle,
                        projectId: v.projectId
                    });
                };
                return React_Basic.empty;
            })() ])(Data_Semigroup.append(Data_Semigroup.semigroupArray)((function () {
                if (v.showCostClassColumnsControl) {
                    return [ TacoCheckbox.component()()({
                        isChecked: v.showCostClassTotalPrice,
                        onToggle: TacoCheckbox.NoEvent.create(v.setShowCostClassTotalPrice(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean))),
                        labelText: Data_Maybe.Just.create(new TacoCheckbox.StringLabel("N\xe4yt\xe4 panoslajien kokonaishinta")),
                        testId: "show-cost-class-columns-control"
                    }), TacoCheckbox.component()()({
                        isChecked: v.showCostClassUnitPrice,
                        onToggle: TacoCheckbox.NoEvent.create(v.setShowCostClassUnitPrice(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean))),
                        labelText: Data_Maybe.Just.create(new TacoCheckbox.StringLabel("N\xe4yt\xe4 panoslajien yksikk\xf6hinta")),
                        testId: "show-cost-class-columns-control-unit-price"
                    }), TacoCheckbox.component()()({
                        isChecked: v.showCostClassHoursPerUnit,
                        onToggle: TacoCheckbox.NoEvent.create(v.setShowCostClassHoursPerUnit(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean))),
                        labelText: Data_Maybe.Just.create(new TacoCheckbox.StringLabel("N\xe4yt\xe4 panoslajien tunnit/yks.")),
                        testId: "show-cost-class-columns-control-hours-per-unit"
                    }) ];
                };
                return [ React_Basic.empty ];
            })())(Data_Semigroup.append(Data_Semigroup.semigroupArray)(showCostClassColumnsItems)([ (function () {
                if (v.showHoursColumnsControl) {
                    return TacoCheckbox.component()()({
                        isChecked: v.showHoursColumns,
                        onToggle: TacoCheckbox.NoEvent.create(v.setShowHoursColumns(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean))),
                        labelText: Data_Maybe.Just.create(new TacoCheckbox.StringLabel("N\xe4yt\xe4 tunnit")),
                        testId: "show-hours-columns-control"
                    });
                };
                return React_Basic.empty;
            })(), (function () {
                if (v.mkExport instanceof Data_Maybe.Just) {
                    return TacoCheckbox.component()()({
                        isChecked: v.addMemoToExcel,
                        onToggle: TacoCheckbox.NoEvent.create(v.onAddMemoToExcelToggle(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean))),
                        labelText: Data_Maybe.Just.create(new TacoCheckbox.StringLabel("N\xe4yt\xe4 muistio Exceliss\xe4")),
                        testId: "show-memo-in-excel-control"
                    });
                };
                if (v.mkExport instanceof Data_Maybe.Nothing) {
                    return React_Basic.empty;
                };
                throw new Error("Failed pattern match at CostEstimation.ViewControls (line 366, column 13 - line 374, column 34): " + [ v.mkExport.constructor.name ]);
            })(), selectionControls({
                referenceModeFollowOptions: v.referenceModeFollowOptions
            }), Data_Maybe.fromMaybe(React_Basic.empty)(Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(v.referenceModeFollowOptions)(function (r) {
                return Box.box("referenceModeFollowOptionsContainer")([ Box.FlexRow.value, Box.Style.create(React_Basic_Emotion.css()({
                    flexWrap: React_Basic_Emotion.str("nowrap")
                })) ])([ TacoCheckbox.component()()({
                    className: "checkbox",
                    isChecked: r.on,
                    onToggle: new TacoCheckbox.NoEvent(r.toggle),
                    labelText: Data_Maybe.Just.create(new TacoCheckbox.StringLabel("Seuraa laskelman rivi\xe4")),
                    color: TacoConstants.PrimaryBlue.value,
                    testId: "follow-by-toggle"
                }), Box.box("followByContainer")([ Box.Width.create(new TacoConstants.CustomSize("100px")) ])([ TacoSelect.component()()({
                    items: r.choices,
                    value: r.by,
                    onChange: r.set,
                    testId: "follow-by-select"
                }) ]) ]);
            })) ])))
        });
        var hasPlanningElementConnections = Data_Maybe.fromMaybe(false)(Data_Functor.map(Data_Maybe.functorMaybe)((function () {
            var $119 = Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Data_Array["null"]);
            return function ($120) {
                return $119((function (v4) {
                    return v4.elementCostGroupConnections;
                })($120));
            };
        })())(v1.value0));
        var groupByControls = (function () {
            var $41 = !v.showGroupByControls;
            if ($41) {
                return Common.emptyArray;
            };
            return [ Data_Monoid.guard(React_Basic.monoidJSX)(subprojectsEnabled)(TacoCheckbox.component()()({
                isChecked: Data_Eq.eq(Data_Maybe.eqMaybe(Types_ComponentsView.eqECGroupByProperty))(v.groupBy)(new Data_Maybe.Just(Types_ComponentsView.GroupBySubproject.value)),
                onToggle: TacoCheckbox.NoEvent.create(v.toggleGroupBy(Types_ComponentsView.GroupBySubproject.value)),
                labelText: Data_Maybe.Just.create(new TacoCheckbox.StringLabel("Ryhmittele alakohteittain")),
                testId: "group-by-subproject-control"
            })), Data_Monoid.guard(React_Basic.monoidJSX)(componentGroupsEnabled)(TacoCheckbox.component()()({
                isChecked: Data_Eq.eq(Data_Maybe.eqMaybe(Types_ComponentsView.eqECGroupByProperty))(v.groupBy)(new Data_Maybe.Just(Types_ComponentsView.GroupByGroup.value)),
                onToggle: TacoCheckbox.NoEvent.create(v.toggleGroupBy(Types_ComponentsView.GroupByGroup.value)),
                labelText: Data_Maybe.Just.create(new TacoCheckbox.StringLabel("Ryhmittele ryhmitt\xe4in")),
                testId: "group-by-group-control"
            })), TacoCheckbox.component()()({
                isChecked: Data_Eq.eq(Data_Maybe.eqMaybe(Types_ComponentsView.eqECGroupByProperty))(v.groupBy)(new Data_Maybe.Just(Types_ComponentsView.GroupByCostGroup.value)),
                onToggle: TacoCheckbox.NoEvent.create(v.toggleGroupBy(Types_ComponentsView.GroupByCostGroup.value)),
                labelText: Data_Maybe.Just.create(new TacoCheckbox.StringLabel("Ryhmittele tavoitelitteroittain")),
                testId: "group-by-cost-group-control"
            }), TacoCheckbox.component()()({
                isChecked: Data_Eq.eq(Data_Maybe.eqMaybe(Types_ComponentsView.eqECGroupByProperty))(v.groupBy)(new Data_Maybe.Just(Types_ComponentsView.GroupByClassification.value)),
                onToggle: new TacoCheckbox.NoEvent(function __do() {
                    Control_Applicative.when(Effect.applicativeEffect)(Data_Eq.notEq(Data_Maybe.eqMaybe(Types_ComponentsView.eqECGroupByProperty))(v.groupBy)(new Data_Maybe.Just(Types_ComponentsView.GroupByClassification.value)))(v.setSortingCriteria(CostEstimation_Types.Code.value))();
                    return v.toggleGroupBy(Types_ComponentsView.GroupByClassification.value)();
                }),
                labelText: Data_Maybe.Just.create(new TacoCheckbox.StringLabel("Ryhmittele nimikkeist\xf6\xf6n")),
                testId: "group-by-classification-control"
            }), Data_Monoid.guard(React_Basic.monoidJSX)(v.showGroupByControls && locationsEnabled)(TacoCheckbox.component()()({
                isChecked: Data_Eq.eq(Data_Maybe.eqMaybe(Types_ComponentsView.eqECGroupByProperty))(v.groupBy)(new Data_Maybe.Just(Types_ComponentsView.GroupByLocation.value)),
                onToggle: TacoCheckbox.NoEvent.create(v.toggleGroupBy(Types_ComponentsView.GroupByLocation.value)),
                labelText: Data_Maybe.Just.create(new TacoCheckbox.StringLabel("Ryhmittele sijainneittain")),
                testId: "group-by-location-control"
            })) ];
        })();
        var groupBySection = (function () {
            var $42 = !v.showGroupByControls;
            if ($42) {
                return Common.emptyArray;
            };
            return [ {
                title: new Data_Maybe.Just("Ryhmittele"),
                items: groupByControls
            } ];
        })();
        var downloadUploadItems = Data_Array.catMaybes([ (function () {
            if (v.viewMode instanceof Types_CostEstimation.EditMode && Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(EstimationUtils.programTypeIsProductionPlanning)(v.programType)) {
                return Data_Maybe.Just.create(uploadEstimationComponents({
                    projectId: v.projectId
                }));
            };
            return Data_Maybe.Nothing.value;
        })(), new Data_Maybe.Just(TacoDropdown.separatorLine), (function () {
            if (v.viewMode instanceof Types_CostEstimation.EditMode && Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(EstimationUtils.programTypeIsProductionPlanning)(v.programType)) {
                return Data_Monoid.guard(Data_Maybe.monoidMaybe(React_Basic.semigroupJSX))(locationsEnabled)(Data_Maybe.Just.create(uploadEstimationQuantityByLocation({
                    projectId: v.projectId
                })));
            };
            return Data_Maybe.Nothing.value;
        })(), Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(v.mkQuantityByLocationExport)(function (mkQuantityByLocationExport) {
            return Data_Monoid.guard(React_Basic.monoidJSX)(locationsEnabled)(EstimationExcelExportButton.estimationQuantityByLocationExcelExportButton({
                mkExport: mkQuantityByLocationExport
            }));
        }), Data_Monoid.guard(Data_Maybe.monoidMaybe(React_Basic.semigroupJSX))(locationsEnabled)(new Data_Maybe.Just(TacoDropdown.separatorLine)), (function () {
            if (v.viewMode instanceof Types_CostEstimation.EditMode && Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(EstimationUtils.programTypeIsProductionPlanning)(v.programType)) {
                return Data_Maybe.Just.create(uploadEstimationQuantityByCostClass({
                    projectId: v.projectId
                }));
            };
            return Data_Maybe.Nothing.value;
        })(), Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(v.mkQuantityByCostClassExport)(function (mkQuantityByCostClassExport) {
            return EstimationExcelExportButton.estimationQuantityByCostClassExcelExportButton({
                mkExport: mkQuantityByCostClassExport
            });
        }), (function () {
            if (v.showTargetCostGroup && v.mkExportPlanningCostGroupsExportParams instanceof Data_Maybe.Just) {
                return new Data_Maybe.Just(TacoDropdown.separatorLine);
            };
            return Data_Maybe.Nothing.value;
        })(), (function () {
            if (v.showTargetCostGroup && v.mkExportPlanningCostGroupsExportParams instanceof Data_Maybe.Just) {
                return Data_Maybe.Just.create(TacoButton.component()()({
                    onClick: new Data_Maybe.Just(appDispatch(new Actions.GetPlanningCostGroupsExport(v.mkExportPlanningCostGroupsExportParams.value0(Data_Unit.unit)))),
                    text: "Vie Ultima XML",
                    icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconFileDownloadV1.value),
                    align: TacoButton_Types.Left.value,
                    testId: "export-planning-cost-groups-button"
                }));
            };
            return Data_Maybe.Nothing.value;
        })(), Data_Monoid.guard(Data_Maybe.monoidMaybe(React_Basic.semigroupJSX))(procumentCSVEnabled)((function () {
            if (v.showTargetCostGroup && v.mkExportPlanningCostGroupsExportParams instanceof Data_Maybe.Just) {
                return Data_Maybe.Just.create(TacoButton.component()()({
                    onClick: new Data_Maybe.Just(appDispatch(new Actions.GetPlanningProcumentExport(v.mkExportPlanningCostGroupsExportParams.value0(Data_Unit.unit)))),
                    text: "Vie tuotantom\xe4\xe4r\xe4t",
                    icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconFileDownloadV1.value),
                    align: TacoButton_Types.Left.value,
                    testId: "export-procument-button"
                }));
            };
            return Data_Maybe.Nothing.value;
        })()), (function () {
            if (v.showTargetCostGroup && v.mkExportPlanningCostGroupsExportParams instanceof Data_Maybe.Just) {
                var adminetIntActive = Data_Maybe.fromMaybe(false)(v2.value0);
                if (adminetIntActive) {
                    return Data_Maybe.Just.create(TacoButton.component()()({
                        onClick: new Data_Maybe.Just(v3.value1(function (old) {
                            return !old;
                        })),
                        text: "Vie Ultimaan (Integraatio)",
                        icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconFileDownloadV1.value),
                        align: TacoButton_Types.Left.value,
                        testId: "export-planning-cost-groups-button-adminet",
                        disabled: !adminetIntActive
                    }));
                };
                return Data_Maybe.Nothing.value;
            };
            return Data_Maybe.Nothing.value;
        })() ]);
        var downloadUploadControls = (function () {
            var $65 = Data_Array["null"](downloadUploadItems);
            if ($65) {
                return Common.emptyArray;
            };
            return Control_Applicative.pure(Control_Applicative.applicativeArray)({
                title: new Data_Maybe.Just("Vie ja tuo m\xe4\xe4r\xe4luetteloita excelist\xe4. Tuonti ylikirjoittaa hankkeella jo olevat m\xe4\xe4r\xe4t."),
                items: downloadUploadItems
            });
        })();
        var downloadPrintoutItems = Data_Array.catMaybes([ Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(v.mkExport)(function (mkExport) {
            return EstimationExcelExportButton.estimationExcelExportButton({
                mkExport: mkExport
            });
        }), Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(v.mkQuantityExport)(function (mkQuantityExport) {
            return EstimationExcelExportButton.estimationQuantityExcelExportButton({
                mkExport: mkQuantityExport
            });
        }), Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(v.mkComponentsExport)(function (mkComponentsExport) {
            return EstimationExcelExportButton.estimationComponentsExcelExportButton({
                mkExport: mkComponentsExport
            });
        }) ]);
        var downloadPrintoutControls = (function () {
            var $66 = Data_Array["null"](downloadPrintoutItems);
            if ($66) {
                return Common.emptyArray;
            };
            return Control_Applicative.pure(Control_Applicative.applicativeArray)({
                title: new Data_Maybe.Just("Tulosta exceliin n\xe4yt\xf6lle ryhmitellyt ja rajatut rivit sek\xe4 n\xe4ytett\xe4v\xe4t sarakkeet"),
                items: downloadPrintoutItems
            });
        })();
        var dropdown = Data_Monoid.guard(Data_Monoid.monoidFn(React_Basic.monoidJSX))(!v3.value0)(TacoDropdown.uncontrolled)({
            sections: Data_Semigroup.append(Data_Semigroup.semigroupArray)(toggleControls)(Data_Semigroup.append(Data_Semigroup.semigroupArray)(groupBySection)(Data_Semigroup.append(Data_Semigroup.semigroupArray)(downloadUploadControls)(downloadPrintoutControls))),
            align: TacoDropdown_Types.AlignRight.value,
            testId: Data_Show.show(Types_CostEstimation.showECViewMode)(v.viewMode) + "-components-view-actions-dropdown"
        });
        var closeModalFn = v3.value1(function (old) {
            return !old;
        });
        var adminetIntegrationModalContainer = React_Basic.element($foreign.integrationModal)({
            closeModal: closeModalFn,
            projectId: v.projectId
        });
        var adminetIntegrationModal = Data_Monoid.guard(React_Basic.monoidJSX)(v3.value0)(adminetIntegrationModalContainer);
        return Box.box("controlsContainer")([ new Box.FlexGrow(1), Box.FlexColumn.value, Box.AlignStretch.value ])([ Controls.component()()({
            leftControls: [ TacoText.component()()({
                text: (function () {
                    if (v.viewMode instanceof Types_CostEstimation.EditMode) {
                        return "Suoritteet";
                    };
                    if (v.viewMode instanceof Types_CostEstimation.ReferenceMode) {
                        return "Viitehankkeiden suoritteet";
                    };
                    throw new Error("Failed pattern match at CostEstimation.ViewControls (line 502, column 25 - line 504, column 66): " + [ v.viewMode.constructor.name ]);
                })(),
                variant: new TacoText_Types.Heading(2),
                wrap: TacoText_Types.NoWrap.value,
                overflow: TacoText_Types.TextOverflowEllipsis.value
            }), filterControls({
                activeFilters: v.activeComponentFilters,
                onSetActiveFilters: function (filters) {
                    return v.setActiveComponentFilters(filters);
                },
                viewMode: v.viewMode,
                showTargetCostGroup: v.showTargetCostGroup
            }), CostEstimation_ProductionPlanningWarning.projectPublishingStateWarning(v.project), Data_Monoid.guard(React_Basic.monoidJSX)(v.showTargetCostGroup && hasPlanningElementConnections)(CostEstimation_ProductionPlanningWarning.productionPlanningElementConnectionsWarning), Box.box("searchInputWrapper")([ Box.Width.create(new TacoConstants.CustomSize("16rem")) ])([ TacoInput.stringInput()()(Data_Eq.eqString)({
                value: v.searchValue,
                placeholder: "Rajaa",
                onChange: v.setSearchValue,
                clearButton: true,
                testId: Data_Show.show(Types_CostEstimation.showECViewMode)(v.viewMode) + "-components-search-input"
            }) ]), TacoSearchInfo.component({}), Data_Monoid.guard(React_Basic.monoidJSX)(exactSearchEnabled)(Box.box("searchInputWrapper")([ Box.Width.create(new TacoConstants.CustomSize("16rem")) ])([ TacoInput.stringInput()()(Data_Eq.eqString)({
                value: v.exactSearchValue,
                placeholder: "Hae",
                validator: function (value) {
                    var $68 = v.exactSearchResultCount === 0 && value !== "";
                    if ($68) {
                        return TacoInput.Valid.value;
                    };
                    return new TacoInput.Invalid("");
                },
                onChange: v.setExactSearchValue,
                clearButton: true,
                testId: Data_Show.show(Types_CostEstimation.showECViewMode)(v.viewMode) + "-components-search-input"
            }) ])), (function () {
                if (v.groupBy instanceof Data_Maybe.Nothing) {
                    return React_Basic.empty;
                };
                if (v.groupBy instanceof Data_Maybe.Just && v.groupBy.value0 instanceof Types_ComponentsView.GroupBySubproject) {
                    return TacoText.component()()({
                        text: "Ryhmittely: alakohde"
                    });
                };
                if (v.groupBy instanceof Data_Maybe.Just && v.groupBy.value0 instanceof Types_ComponentsView.GroupByGroup) {
                    return TacoText.component()()({
                        text: "Ryhmittely: ryhm\xe4"
                    });
                };
                if (v.groupBy instanceof Data_Maybe.Just && v.groupBy.value0 instanceof Types_ComponentsView.GroupByCostGroup) {
                    return TacoText.component()()({
                        text: "Ryhmittely: tavoitelittera"
                    });
                };
                if (v.groupBy instanceof Data_Maybe.Just && v.groupBy.value0 instanceof Types_ComponentsView.GroupByClassification) {
                    return TacoText.component()()({
                        text: "Ryhmittely: nimikkeist\xf6"
                    });
                };
                if (v.groupBy instanceof Data_Maybe.Just && v.groupBy.value0 instanceof Types_ComponentsView.GroupByLocation) {
                    return TacoText.component()()({
                        text: "Ryhmittely: sijainti"
                    });
                };
                throw new Error("Failed pattern match at CostEstimation.ViewControls (line 539, column 15 - line 550, column 70): " + [ v.groupBy.constructor.name ]);
            })() ],
            rightControls: [ dropdown, Data_Maybe.maybe(React_Basic.empty)(function (v4) {
                return v4.refProjectsModal;
            })(v.referenceModeFollowOptions), adminetIntegrationModal ],
            gap: true,
            bottomGutter: false
        }) ]);
    };
});
module.exports = {
    filterControls: filterControls,
    selectionControls: selectionControls,
    controls: controls
};
