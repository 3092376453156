import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  CellClassParams,
  ColDef,
  ColGroupDef,
  ICellRendererParams,
  ValueGetterParams,
} from "ag-grid-community";
import { getHours } from "../Pro/OfferPageTableFunctions";
import {
  getPercentageOfTotal,
  getMeasurementValue,
  getSocialRow,
  getTotalRowWithoutSocial,
  getTotalRowWithSocial,
  getCostItemsRow,
  getGeneralRow,
  getVATRow,
  getSpecialRow,
  getMarginRow,
  getRiskAndChangeRows,
  getTotalWithAdditionalCosts,
  getAdditionalPercentage,
  getBaseTotal,
  getSubtotalRow,
  getAdditionalTotal,
  getGrandTotalWithCostItems,
} from "./PremiumOfferPageFunctions";
import { costClassColumnDefinitions } from "../costClassColumnDefnitions";
import { PremiumOfferData } from "./PremiumOfferPage";
import { Table } from "../../../../components/Table";
import { AgGridReact } from "ag-grid-react";
import { Measurement } from "../../../../../../ts-bindings/Measurement";
import { useTranslation } from "react-i18next";
import { useRowDataUpdater } from "../../../../components/Table/useRowDataUpdater";
import { OfferParams } from "../useOfferParamsQuery";
import { PremiumOfferButtons } from "./PremiumOfferButtons";
import { Button, IconEditV1 } from "@tocoman/ui";
import { EditRowModal } from "./EditRowModal";
import { EditMarginModal } from "./EditMarginModal";
import { useWorkerState } from "../../../../hooks/useWorkerState";
import { defaultCurrency } from "../../../../utils/currency";

type PremiumOfferPageTableProps = {
  projectId: number;
  tableRowData: PremiumOfferData[] | null;
  measurementUnits: Measurement[] | null;
  offerParams: OfferParams;
};

export type PremiumOfferSumRowData = {
  code: string;
  description: string;
  hours: number | null;
  hourlyRate: number | null;
  percentageOfTotal: number;
  costClasses: SumRowCostClass[];
  measurements: Measurement[] | null;
  total: number;
};

export type SumRowCostClass = {
  costClassCode: string;
  name: string;
  baseTotal: number;
  total: number;
  totalWithSocial: number;
  socialExpensePrice: number;
  socialExpensesWithAdditionalCosts?: number;
  socialPercentage?: number;
  additionalTotal?: number;
};

export type CostItemCostClass = {
  costClassCode: string;
  name: string;
  baseTotal: number;
  risk?: number;
  change?: number;
  general?: number;
};

export type CostItemRow = {
  code: string;
  description: string | null;
  baseTotal: number;
  additionalTotal?: number;
  total: number;
  visible: boolean | null;
  costClasses?: CostItemCostClass[];
};

export type AdditionalRow = {
  code: string;
  description: string | null;
  baseTotal: number;
  additionalPercentage?: number;
  additionalTotal: number;
  totalWithAdditionalCosts: number;
  visible: boolean | null;
  costClasses?: SumRowCostClass[];
  measurements?: Measurement[] | null;
  costItems?: CostItemRow[];
};

export type RowType =
  | "risk"
  | "general"
  | "change"
  | "specialContract"
  | "margin"
  | null;

export const PremiumOfferPageTable = ({
  projectId,
  tableRowData,
  measurementUnits,
  offerParams,
}: PremiumOfferPageTableProps) => {
  const gridRef = useRef<AgGridReact>(null);
  const detailGridRef = useRef<AgGridReact>(null);
  const { t } = useTranslation("estimation", { keyPrefix: "offerPage" });

  const projectDetails = useWorkerState("ProjectDetailsDataState", projectId);
  const [dataReady, setDataReady] = useState(false);

  const [currentRowData, setCurrentRowData] = useState<
    PremiumOfferData[] | null
  >(null);

  const [currentOfferParams, setCurrentOfferParams] = useState<OfferParams>(
    offerParams
  );

  const [rowToAdd, setRowToAdd] = useState<string | null>(null);
  const [typeToEdit, setTypeToEdit] = useState<RowType>(null);
  const [editRowModalOpen, setEditRowModalOpen] = useState(false);
  const [editMarginModalOpen, setEditMarginModalOpen] = useState(false);

  const handleEditClicked = useCallback((type: RowType) => {
    if (type === "margin") {
      setEditMarginModalOpen(true);
    } else {
      setTypeToEdit(type);
      setEditRowModalOpen(true);
    }
  }, []);

  const measurementColumns = useMemo(
    () =>
      measurementUnits?.map((unit) => ({
        colId: unit.unit.name,
        headerName: unit.unit.name,
        type: ["twoDecimal", "numericColumn"],
        valueGetter: (params: ValueGetterParams) =>
          getMeasurementValue(params, unit),
        initialWidth: 120,
        initialHide: true,
      })) ?? [],
    [measurementUnits]
  );

  const columnDefs = useMemo<
    (ColDef<PremiumOfferData> | ColGroupDef<PremiumOfferData>)[]
  >(() => {
    if (!tableRowData) {
      return [];
    }
    const columns: (
      | ColDef<PremiumOfferData>
      | ColGroupDef<PremiumOfferData>
    )[] = [
      {
        colId: "codeAndDescription",
        headerName: t`columns.classification`,
        initialWidth: 300,
        valueGetter: (params: ValueGetterParams) => {
          if (
            params.data.code === "grandTotal" ||
            params.data.code === "costItems" ||
            params.data.code === "social" ||
            params.data.code === "grandTotalWithSocial" ||
            params.data.code === "VAT" ||
            params.data.code === "grandTotalWithCostItems"
          ) {
            return params.data.description;
          }
          return `${params.data.code} - ${params.data.description}`;
        },
        cellRenderer: "agGroupCellRenderer",
      },
      {
        colId: "hourlyRate",
        headerName: t`columns.hourlyRate`,
        type: ["twoDecimal"],
        valueGetter: (params: ValueGetterParams) => params.data.hourlyRate,
        initialWidth: 160,
      },
      {
        colId: "hours",
        headerName: t`columns.hours`,
        type: ["twoDecimal"],
        valueGetter: getHours,
        initialWidth: 90,
      },
    ];

    const restOfColumns = [
      {
        colId: "total",
        headerName: t`columns.totalPrice`,
        type: ["money"],
        valueGetter: (params: ValueGetterParams) => params.data.total,
        initialWidth: 150,
      },
      {
        colId: "percentageOfTotal",
        headerName: t`columns.percentageOfTotal`,
        type: ["percentageWithZeroValue"],
        valueGetter: getPercentageOfTotal,
        initialWidth: 150,
      },
    ];
    const costClassColumns = costClassColumnDefinitions(
      tableRowData[0].costClasses,
      t
    );
    return [
      ...columns,
      ...costClassColumns,
      ...restOfColumns,
      ...measurementColumns,
    ];
  }, [measurementColumns, t, tableRowData]);

  const defaultColDef = {
    cellClassRules: {
      bold: (params: CellClassParams) => {
        return (
          params.data.code === "grandTotal" ||
          params.data.code === "grandTotalWithSocial" ||
          params.data.code === "grandTotalWithCostItems"
        );
      },
    },
    sortable: false,
  };

  const sideBar = {
    toolPanels: [
      {
        id: "columns",
        labelKey: "columns",
        labelDefault: "Columns",
        iconKey: "columns",
        toolPanel: "agColumnsToolPanel",
        toolPanelParams: {
          suppressPivotMode: true,
          suppressRowGroups: true,
          suppressValues: true,
        },
      },
    ],
  };
  const onFirstDataRendered = () => {
    setDataReady(true);
  };

  const totalRowWithoutSocial: PremiumOfferSumRowData = useMemo(() => {
    return getTotalRowWithoutSocial(gridRef, t);
  }, [dataReady]);

  const socialRow = useMemo(() => getSocialRow(gridRef, t), [dataReady]);

  const totalRowWithSocial: PremiumOfferSumRowData = useMemo(
    () => getTotalRowWithSocial(gridRef, t),
    [dataReady]
  );

  const savedRiskDescription = offerParams?.rowDescriptions
    ?.find((row) => row.key === "risk")
    ?.value.toString();
  const [riskRowVisible, setRiskRowVisible] = useState(true);
  const [riskRowDescription, setRiskRowDescription] = useState<string>(
    t("risk")
  );

  useEffect(() => {
    if (savedRiskDescription) {
      setRiskRowDescription(savedRiskDescription);
    }
  }, [savedRiskDescription]);

  const riskRow = useMemo(
    () =>
      getRiskAndChangeRows(
        currentOfferParams,
        totalRowWithoutSocial,
        riskRowVisible,
        "risk",
        riskRowDescription,
        t
      ),
    [dataReady, riskRowDescription, riskRowVisible, currentOfferParams]
  );

  const savedChangeDescription = offerParams?.rowDescriptions
    ?.find((row) => row.key === "change")
    ?.value.toString();
  const [changeRowVisible, setChangeRowVisible] = useState(true);
  const [changeRowDescription, setChangeRowDescription] = useState<string>(
    t("change")
  );

  useEffect(() => {
    if (savedChangeDescription) {
      setChangeRowDescription(savedChangeDescription);
    }
  }, [savedChangeDescription]);

  const changeRow = useMemo(
    () =>
      getRiskAndChangeRows(
        currentOfferParams,
        totalRowWithoutSocial,
        changeRowVisible,
        "change",
        changeRowDescription,
        t
      ),
    [dataReady, changeRowDescription, changeRowVisible, currentOfferParams]
  );

  const savedSpecialDescription = offerParams?.rowDescriptions
    ?.find((row) => row.key === "specialContract")
    ?.value.toString();
  const [specialRowVisible, setSpecialRowVisible] = useState(true);
  const [specialRowDescription, setSpecialRowDescription] = useState<string>(
    t("specialContract")
  );

  useEffect(() => {
    if (savedSpecialDescription) {
      setSpecialRowDescription(savedSpecialDescription);
    }
  }, [savedSpecialDescription]);

  const specialRow = useMemo(
    () =>
      getSpecialRow(
        currentOfferParams,
        specialRowVisible,
        specialRowDescription
      ),
    [dataReady, specialRowDescription, specialRowVisible, currentOfferParams]
  );

  const subtotalRow = useMemo(() => {
    return getSubtotalRow(
      totalRowWithSocial,
      riskRow,
      changeRow,
      specialRow,
      t
    );
  }, [dataReady, riskRow, changeRow, specialRow]);

  const savedGeneralDescription = offerParams?.rowDescriptions
    ?.find((row) => row.key === "general")
    ?.value.toString();
  const [generalRowVisible, setGeneralRowVisible] = useState(true);
  const [generalRowDescription, setGeneralRowDescription] = useState<string>(
    t("general")
  );

  useEffect(() => {
    if (savedGeneralDescription) {
      setGeneralRowDescription(savedGeneralDescription);
    }
  }, [savedGeneralDescription]);

  const generalRow = useMemo(
    () =>
      getGeneralRow(
        currentOfferParams,
        subtotalRow,
        riskRow,
        changeRow,
        specialRow,
        generalRowVisible,
        generalRowDescription
      ),
    [
      dataReady,
      generalRowDescription,
      generalRowVisible,
      currentOfferParams,
      subtotalRow,
      riskRow,
      changeRow,
    ]
  );

  const [marginRowVisible, setMarginRowVisible] = useState(true);

  const marginRow = useMemo(
    () => getMarginRow(currentOfferParams, generalRow, marginRowVisible, t),
    [dataReady, generalRow]
  );

  const [detailDataRendered, setDetailDataRendered] = useState(false);

  const allAdditionalRows = useMemo(() => {
    return (
      [riskRow, generalRow, changeRow, subtotalRow, specialRow, marginRow] ?? []
    );
  }, [
    riskRow,
    generalRow,
    changeRow,
    subtotalRow,
    specialRow,
    marginRow,
    detailDataRendered,
  ]);

  const visibleAdditionalRows = useMemo(() => {
    return (
      [
        riskRow,
        changeRow,
        specialRow,
        subtotalRow,
        generalRow,
        marginRow,
      ].filter((row) => row?.visible) ?? []
    );
  }, [
    riskRow,
    generalRow,
    changeRow,
    subtotalRow,
    specialRow,
    marginRow,
    detailDataRendered,
  ]);

  const costItemsRow = useMemo(() => {
    return getCostItemsRow(allAdditionalRows, t);
  }, [dataReady, riskRow]);

  const VATRow = useMemo(() => {
    return getVATRow(
      gridRef,
      allAdditionalRows,
      projectDetails?.taxRate ?? 0,
      t
    );
  }, [dataReady, allAdditionalRows, projectDetails?.taxRate]);

  const grandTotalWithCostItemsRow = useMemo(() => {
    return getGrandTotalWithCostItems(
      costItemsRow.total,
      totalRowWithSocial.total,
      VATRow.total,
      t
    );
  }, [dataReady, costItemsRow, totalRowWithSocial, VATRow]);

  const totalRows = useMemo(() => {
    if (
      dataReady &&
      totalRowWithoutSocial &&
      socialRow &&
      totalRowWithSocial &&
      costItemsRow &&
      VATRow &&
      grandTotalWithCostItemsRow
    ) {
      return [
        totalRowWithoutSocial,
        socialRow,
        totalRowWithSocial,
        costItemsRow,
        VATRow,
        grandTotalWithCostItemsRow,
      ];
    }
    return [];
  }, [
    dataReady,
    totalRowWithoutSocial,
    socialRow,
    totalRowWithSocial,
    costItemsRow,
    VATRow,
    grandTotalWithCostItemsRow,
  ]);

  useRowDataUpdater(tableRowData, gridRef, totalRows);

  useEffect(() => {
    if (tableRowData) {
      const curr = gridRef.current?.api?.getGridOption(
        "rowData"
      ) as PremiumOfferData[];
      setCurrentRowData(curr);
    }
  }, [totalRows]);

  const changeVisibility = useCallback((code: string, visibility: boolean) => {
    const setters: Record<string, (value: boolean) => void> = {
      risk: setRiskRowVisible,
      general: setGeneralRowVisible,
      change: setChangeRowVisible,
      specialContract: setSpecialRowVisible,
      margin: setMarginRowVisible,
    };

    const setRowVisible = setters[code];

    if (setRowVisible) {
      setRowVisible(visibility);
    }
  }, []);

  useEffect(() => {
    changeVisibility(rowToAdd ?? "", true);
    setRowToAdd(null);
  }, [rowToAdd]);

  const detailColDefs = useMemo(
    () => [
      {
        field: "description",
        headerName: t`columns.additionalRowsDescription`,
        initialWidth: 300,
      },
      {
        colId: "edit",
        headerName: "",
        cellRenderer: (params: ICellRendererParams) => {
          if (params.data.code === "subtotal") {
            return null;
          }
          return (
            <div className={"flex items-center"}>
              <Button
                className={"self-center"}
                icon={IconEditV1}
                variant={"text"}
                onClick={() => handleEditClicked(params.data.code)}
              />
            </div>
          );
        },
      },
      {
        field: "baseTotal",
        headerName: t`columns.basePrice`,
        type: ["money"],
        initialWidth: 150,
        valueGetter: getBaseTotal,
      },
      {
        field: "additionalPercentage",
        headerName: t`columns.additionalPercentage`,
        type: ["percentage"],
        initialWidth: 150,
        valueGetter: getAdditionalPercentage,
      },
      {
        colId: "additionalTotal",
        headerName: t`columns.additionalTotal`,
        type: ["money"],
        initialWidth: 150,
        valueGetter: getAdditionalTotal,
      },
      {
        field: "totalWithAdditionalCosts",
        headerName: t`columns.totalPrice`,
        type: ["money"],
        initialWidth: 150,
        valueGetter: getTotalWithAdditionalCosts,
      },
    ],
    [handleEditClicked, t]
  );

  const detailDefaultColDef = {
    cellClassRules: {
      bold: (params: CellClassParams) => {
        return params.data.code === "subtotal";
      },
    },
    sortable: false,
    filter: false,
    flex: 1,
  };

  const detailCellRenderer = useCallback(() => {
    return (
      <Table
        gridRef={detailGridRef}
        rowData={visibleAdditionalRows}
        columnDefs={detailColDefs}
        domLayout={"autoHeight"}
        defaultColDef={detailDefaultColDef}
        onFirstDataRendered={() => setDetailDataRendered(true)}
        context={{
          currency: projectDetails?.currency,
        }}
      />
    );
  }, [visibleAdditionalRows]);

  useEffect(() => {
    if (gridRef.current?.api) {
      gridRef.current.api.getRowNode("costItems")?.setExpanded(true);
    }
  }, [visibleAdditionalRows]);

  const handleDeleteRow = useCallback((code: string) => {
    changeVisibility(code, false);
  }, []);

  return (
    <>
      <Table<PremiumOfferData>
        gridRef={gridRef}
        className={"h-3/4 m-5"}
        rowData={tableRowData}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        sideBar={sideBar}
        getRowId={(row) => row.data.code}
        onFirstDataRendered={onFirstDataRendered}
        masterDetail
        isRowMaster={(data) => data.code === "costItems"}
        detailRowAutoHeight={true}
        detailCellRenderer={detailCellRenderer}
        context={{
          costClasses: tableRowData?.[0]?.costClasses,
          measurementUnits,
          currency: projectDetails?.currency,
        }}
      />

      <PremiumOfferButtons
        data={visibleAdditionalRows}
        handleAddRow={setRowToAdd}
      />
      <EditRowModal
        projectId={projectId}
        type={typeToEdit}
        open={editRowModalOpen}
        updateOfferParams={setCurrentOfferParams}
        rowData={currentRowData}
        onClose={() => setEditRowModalOpen(false)}
        deleteRow={handleDeleteRow}
        additionalRows={allAdditionalRows}
        currency={projectDetails?.currency ?? defaultCurrency}
      />
      <EditMarginModal
        projectId={projectId}
        open={editMarginModalOpen}
        deleteRow={handleDeleteRow}
        onClose={() => setEditMarginModalOpen(false)}
        generalRow={generalRow}
        currency={projectDetails?.currency ?? defaultCurrency}
      />
    </>
  );
};
